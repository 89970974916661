import React, { useState } from 'react';
import axios from 'axios';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import Table from './Table/Table';
import FilterActionBar from './FilterActionBar/FilterActionBar';

const columnMap = {
  storeName: 'Store',
  oloRef: 'Order ID/Invoice #',
  timeWanted: 'Time Wanted',
  timePlaced: 'Time Placed',
  name: 'Booking Client Name',
  emailAddress: 'Booking Client Email',
  phoneNumber: 'Phone',
  total: 'Total',
  tax: 'Tax',
  tip: 'Tip',
  deliveryAddress: 'Delivery Address',
  deliveryZipcode: 'Delivery Zipcode',
};

const MainContent = ({
  firebaseUser,
  searchField,
  handleChangeSearchField,
  searchInput,
  handleSearch,
  handleClear,
  handleViewInvoice,
  handleChangeSelectedStore,
  orders,
  showStoreFilter,
  selectedHandoffType,
  setSelectedHandoffType,
  selectedOloStore,
  stores,
  setLoading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderUnacknowledged, setOrderUnacknowledged] = useState('desc');
  const [orderByUnacknowledged, setOrderByUnacknowledged] =
    useState('timeWanted');
  const [orderAcknowledged, setOrderAcknowledged] = useState('desc');
  const [orderByAcknowledged, setOrderByAcknowledged] = useState('timeWanted');
  const [selectedUnacknowledgedOrders, setSelectedUnacknowledgedOrders] =
    useState({});
  const [selectedAcknowledgedOrders, setSelectedAcknowledgedOrders] = useState(
    {}
  );
  const [activeTable, setActiveTable] = useState(null); // Tracks which table is currently active

  const handleExport = async () => {
    try {
      const fileName = prompt(
        'Enter the file name for the export (without extension):'
      );
      if (!fileName) {
        alert('Export cancelled. Please provide a valid file name.');
        return;
      }
      setLoading(true);
      const sortedOrders = stableSort(
        orders,
        getComparator(orderAcknowledged, orderByAcknowledged)
      );

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      const response = await axios.post(
        '/hd-api/olo/export',
        { orders: sortedOrders },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          responseType: 'blob',
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Failed to export orders:', error);
    }
  };

  const handleToggleAcknowledged = async () => {
    try {
      setLoading(true);

      const isAcknowledged = activeTable === 'acknowledged';
      const selectedOrderIds = Object.keys(
        isAcknowledged
          ? selectedAcknowledgedOrders
          : selectedUnacknowledgedOrders
      ).filter((id) =>
        isAcknowledged
          ? selectedAcknowledgedOrders[id]
          : selectedUnacknowledgedOrders[id]
      );

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      await axios.post(
        `/hd-api/olo/email=${firebaseUser.email}/mark=${
          !isAcknowledged ? 1 : 0
        }`,
        { orders: selectedOrderIds },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        }
      );
      // Clear the selected orders after the action
      setSelectedUnacknowledgedOrders({});
      setSelectedAcknowledgedOrders({});
      setActiveTable(null);
      handleSearch();
    } catch (error) {
      // Clear the selected orders after the action
      setSelectedUnacknowledgedOrders({});
      setSelectedAcknowledgedOrders({});
      setActiveTable(null);
      setLoading(false);
      console.error('Failed to mark orders:', error);
    }
  };

  const handleRequestSort = (event, property, table) => {
    const isAsc =
      (table === 'unacknowledged'
        ? orderByUnacknowledged
        : orderByAcknowledged) === property &&
      (table === 'unacknowledged' ? orderUnacknowledged : orderAcknowledged) ===
        'asc';
    if (table === 'unacknowledged') {
      setOrderUnacknowledged(isAsc ? 'desc' : 'asc');
      setOrderByUnacknowledged(property);
    } else {
      setOrderAcknowledged(isAsc ? 'desc' : 'asc');
      setOrderByAcknowledged(property);
    }
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === 'timeWanted' || orderBy === 'timePlaced') {
      return new Date(b[orderBy]) - new Date(a[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = (array, comparator) => {
    if (array?.length > 0) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    } else {
      return [];
    }
  };

  const handleRowClick = (oloRef, isAcknowledged) => {
    if (isAcknowledged) {
      setSelectedUnacknowledgedOrders({});
      setActiveTable('acknowledged');
      setSelectedAcknowledgedOrders((prev) => ({
        ...prev,
        [oloRef]: !prev[oloRef],
      }));
    } else {
      setSelectedAcknowledgedOrders({});
      setActiveTable('unacknowledged');
      setSelectedUnacknowledgedOrders((prev) => ({
        ...prev,
        [oloRef]: !prev[oloRef],
      }));
    }
  };

  const handleChangeHeaderCheckbox = (event, isAcknowledged) => {
    const newChecked = event.target.checked;
    const visibleOrders = stableSort(
      isAcknowledged ? acknowledgedOrders : unacknowledgedOrders,
      getComparator(
        isAcknowledged ? orderAcknowledged : orderUnacknowledged,
        isAcknowledged ? orderByAcknowledged : orderByUnacknowledged
      )
    )
      .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
      .reduce(
        (acc, order) => ({
          ...acc,
          [order.oloRef]: newChecked,
        }),
        {}
      );

    if (isAcknowledged) {
      setSelectedAcknowledgedOrders(visibleOrders);
      setSelectedUnacknowledgedOrders({});
      setActiveTable('acknowledged');
    } else {
      setSelectedUnacknowledgedOrders(visibleOrders);
      setSelectedAcknowledgedOrders({});
      setActiveTable('unacknowledged');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let goghUser = firebaseUser.email.toLowerCase().includes('gogh');

  const acknowledgedOrders = orders.filter((order) => order.acknowledged);
  const unacknowledgedOrders = orders.filter((order) => !order.acknowledged);

  const toggleAcknowledgedText =
    activeTable === 'acknowledged'
      ? 'Mark as Unacknowledged'
      : 'Mark as Acknowledged';

  return (
    <div>
      <FilterActionBar
        firebaseUser={firebaseUser}
        activeTable={activeTable}
        searchField={searchField}
        handleChangeSearchField={handleChangeSearchField}
        searchInput={searchInput}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleExport={handleExport}
        handleToggleAcknowledged={handleToggleAcknowledged}
        toggleAcknowledgedText={toggleAcknowledgedText}
        selectedOrders={
          activeTable === 'acknowledged'
            ? selectedAcknowledgedOrders
            : selectedUnacknowledgedOrders
        }
        showStoreFilter={showStoreFilter}
        selectedHandoffType={selectedHandoffType}
        setSelectedHandoffType={setSelectedHandoffType}
        selectedOloStore={selectedOloStore}
        handleChangeSelectedStore={handleChangeSelectedStore}
        stores={stores}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }}
      >
        <Accordion
          defaultExpanded
          style={{ width: '100%', backgroundColor: '#f8f8f8', marginBottom: 0 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1bh-content'
            id='panel1bh-header'
          >
            <Typography variant='h5' style={{ fontFamily: 'Lato' }}>
              Unacknowledged Orders
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table
              orders={unacknowledgedOrders}
              order={orderUnacknowledged}
              orderBy={orderByUnacknowledged}
              handleRequestSort={(event, property) =>
                handleRequestSort(event, property, 'unacknowledged')
              }
              onRowClick={(oloRef) => handleRowClick(oloRef, false)}
              onHeaderCheckboxChange={(event) =>
                handleChangeHeaderCheckbox(event, false)
              }
              selectedOrders={selectedUnacknowledgedOrders}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              getComparator={getComparator}
              stableSort={stableSort}
              page={page}
              columnMap={columnMap}
              goghUser={goghUser}
              handleViewInvoice={handleViewInvoice}
              acknowledged={false}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ width: '100%', backgroundColor: '#f8f8f8' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h5' style={{ fontFamily: 'Lato' }}>
              Acknowledged Orders
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table
              orders={acknowledgedOrders}
              order={orderAcknowledged}
              orderBy={orderByAcknowledged}
              handleRequestSort={(event, property) =>
                handleRequestSort(event, property, 'acknowledged')
              }
              onRowClick={(oloRef) => handleRowClick(oloRef, true)}
              onHeaderCheckboxChange={(event) =>
                handleChangeHeaderCheckbox(event, true)
              }
              selectedOrders={selectedAcknowledgedOrders}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              getComparator={getComparator}
              stableSort={stableSort}
              page={page}
              columnMap={columnMap}
              goghUser={goghUser}
              handleViewInvoice={handleViewInvoice}
              acknowledged={true}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default MainContent;
