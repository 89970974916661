import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { useUserAuth } from '../../context/UserAuthContext';
import Header from '../../components/Header/Header';
import StoreSelectionDialog from './StoreSelectionDialog';
import DrawerContent from './Drawer/Drawer';
import MainContent from './MainContent/MainContent';
import TypeSelectionDialog from './TypeSelectionDialog';
import ExcludedTypeSelectionDialog from './ExcludedTypeSelectionDialog';
import PreferredCompetitorDialog from '../../components/PreferredCompetitorDialog/PreferredCompetitorDialog';

import './PricingComp.css';

const drawerWidth = 250;

const App = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
  });
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [radius, setRadius] = useState(5);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user: firebaseUser, loading: authenticating } = useUserAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [competitors, setCompetitors] = useState([]);
  const [includedTypesDialogOpen, setIncludedTypesDialogOpen] = useState(false);
  const [excludedTypesDialogOpen, setExcludedTypesDialogOpen] = useState(false);
  const [includedTypes, setIncludedTypes] = useState([]);
  const [excludedTypes, setExcludedTypes] = useState([]);
  const [highlightedStore, setHighlightedStore] = useState();
  const [sortingMethod, setSortingMethod] = useState('distance');
  const [searchType, setSearchType] = useState('nearBy');
  const [openDialog, setOpenDialog] = useState(false);
  const [preferredCompetitors, setPreferredCompetitors] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetStores();
      handleGetUserInfo();
    }
  }, [firebaseUser]);

  useEffect(() => {
    if (searchType === 'specific' && excludedTypes.length > 0) {
      setExcludedTypes(excludedTypes[0]);
    }
    setPreferredCompetitors([]);
    setIncludedTypes([]);
    setSortingMethod('distance');
  }, [searchType]);

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        navigate('/profile');
      } else {
        setFormData(response.data.userInfo);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetStores = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/stores/${firebaseUser.email}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };
  const handleGetCompetitorRestaurants = async (
    storeId,
    pageToken = undefined
  ) => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.post(
        `/hd-api/places/store=${storeId}/locations`,
        {
          preferredLocations: preferredCompetitors.map(
            ({ name }) => `"${name}"`
          ),
          searchType,
          radius,
          includedTypes: includedTypes.length > 0 ? includedTypes : undefined,
          excludedTypes: excludedTypes.length > 0 ? excludedTypes : undefined,
          sortingMethod,
          pageToken,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (pageToken) {
        setCompetitors([...competitors, ...response.data.places]);
      } else {
        setCompetitors(response.data.places);
      }
      setNextPageToken(response.data.nextPageToken);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (nextPageToken) {
      handleGetCompetitorRestaurants(highlightedStore, nextPageToken);
    }
  };

  const handleRadiusChange = (event, newValue) => {
    setRadius(newValue);
  };

  const showIgnoreCompetitors = () => {};

  const showPreferredCompetitors = () => {
    setOpenDialog(true);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDialogToggle = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleDeleteChip = (storeToDelete) => () => {
    const updatedStores = selectedStores.filter(
      (store) => store !== storeToDelete
    );
    if (updatedStores.length > 0) {
      setHighlightedStore(updatedStores[0].storeId);
      handleGetCompetitorRestaurants(updatedStores[0].storeId);
    }
    setSelectedStores(updatedStores);
  };

  const handleDialogConfirm = (newSelectedStores) => {
    setSelectedStores(newSelectedStores);
    setDialogOpen(false);
    setHighlightedStore(newSelectedStores[0].storeId);
    if (
      searchType === 'nearBy' ||
      (searchType === 'specific' && preferredCompetitors.length > 0)
    ) {
      handleGetCompetitorRestaurants(newSelectedStores[0].storeId);
    }
  };

  const handleSearch = () => {
    if (searchType === 'specific' && preferredCompetitors.length === 0) {
      window.alert('Please select at least one competitor.');
    } else {
      handleGetCompetitorRestaurants(highlightedStore);
    }
  };

  const handleClosePreferredCompetitorDialog = () => {
    handleSearch();
    setOpenDialog(false);
  };

  const handleDialogConfirmTypes = (setDialogOpen) => {
    setDialogOpen(false);
  };

  const handleTypeToggle = (type, setType) => {
    setType((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  const handleClickedChip = (store) => {
    handleGetCompetitorRestaurants(store.storeId);
    setHighlightedStore(store.storeId);
  };

  const handleSortingChange = (e) => {
    setSortingMethod(e.target.value);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleIncludedTypeToggle = (type) =>
    handleTypeToggle(type, setIncludedTypes);
  const handleExcludedTypeToggle = (type) =>
    handleTypeToggle(type, setExcludedTypes);
  return (
    <div>
      <Header />

      <Box display='flex'>
        <div id='main'></div>

        <CssBaseline />

        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          <DrawerContent
            radius={radius}
            handleRadiusChange={handleRadiusChange}
            handleDialogToggle={handleDialogToggle}
            handleSearchTypeChange={handleSearchTypeChange}
            handleSearch={handleSearch}
            highlightedStore={highlightedStore}
            showPreferredCompetitors={showPreferredCompetitors}
            showIgnoreCompetitors={showIgnoreCompetitors}
            selectedStores={selectedStores}
            setExcludedTypesDialogOpen={setExcludedTypesDialogOpen}
            setIncludedTypesDialogOpen={setIncludedTypesDialogOpen}
            handleGetCompetitorRestaurants={handleGetCompetitorRestaurants}
            searchType={searchType}
            sortingMethod={sortingMethod}
            handleSortingChange={handleSortingChange}
          />
        </Drawer>

        {isMobile && (
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={handleDrawerToggle}
            sx={{ position: 'fixed', top: 16, left: 16 }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <MainContent
          authenticating={authenticating}
          competitors={competitors}
          handleClickedChip={handleClickedChip}
          handleDeleteChip={handleDeleteChip}
          handleDialogToggle={handleDialogToggle}
          highlightedStore={highlightedStore}
          loading={loading}
          selectedStores={selectedStores}
          nextPageToken={nextPageToken}
          handleLoadMore={handleLoadMore}
        />
        <TypeSelectionDialog
          open={includedTypesDialogOpen}
          onClose={() => setIncludedTypesDialogOpen(false)}
          selectedTypes={includedTypes}
          onTypeToggle={handleIncludedTypeToggle}
          onConfirm={() => handleDialogConfirmTypes(setIncludedTypesDialogOpen)}
        />

        <ExcludedTypeSelectionDialog
          open={excludedTypesDialogOpen}
          onClose={() => setExcludedTypesDialogOpen(false)}
          selectedTypes={excludedTypes}
          onTypeToggle={handleExcludedTypeToggle}
          onConfirm={() => handleDialogConfirmTypes(setExcludedTypesDialogOpen)}
          searchType={searchType}
        />
        <StoreSelectionDialog
          open={dialogOpen}
          onClose={handleDialogToggle}
          stores={stores}
          selectedStores={selectedStores}
          onConfirm={handleDialogConfirm}
        />
        <PreferredCompetitorDialog
          open={openDialog}
          handleClose={handleClosePreferredCompetitorDialog}
          preferredCompetitors={preferredCompetitors}
          setPreferredCompetitors={setPreferredCompetitors}
        />
      </Box>
    </div>
  );
};

export default App;
