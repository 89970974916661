import React from 'react';
import {
  Alert,
  TextField,
  Button,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';

const OrderForm = ({
  handleChange,
  handleSearch,
  handleSave,
  orderDetails,
  showSnackbar,
  handleChangeSearchOrderId,
  handleSnackbarClose,
  snackbarMessage,
  searchOrderId,
}) => {
  const {
    store,
    customFee,
    poNumber,
    tax,
    tip,
    subtotal,
    total,
    discount,
    deliveryFee,
    orderId,
  } = orderDetails;

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} md={6}>
        <Paper style={{ margin: 16, padding: 24 }}>
          <Typography variant='h6' style={{ fontFamily: 'Lato' }} gutterBottom>
            Search Order
          </Typography>
          <TextField
            label='Order ID'
            fullWidth
            value={searchOrderId || ''}
            name='searchOrderId'
            onChange={handleChangeSearchOrderId}
          />
          <Button
            style={{ marginTop: 16, fontFamily: 'Lato' }}
            variant='contained'
            color='primary'
            onClick={handleSearch}
          >
            Search
          </Button>
          {orderDetails && poNumber !== undefined && (
            <div style={{ marginTop: 16, fontFamily: 'Lato' }}>
              <Typography
                variant='h6'
                style={{ fontFamily: 'Lato' }}
                gutterBottom
              >
                Order Details
              </Typography>
              <TextField
                style={{ margin: 8 }}
                label='Store'
                disabled
                fullWidth
                value={store}
              />
              <TextField
                style={{ margin: 8 }}
                label='Order ID'
                fullWidth
                disabled
                value={orderId}
              />
              <TextField
                style={{ margin: 8 }}
                label='PO Number'
                fullWidth
                value={poNumber || ''}
                name='poNumber'
                onChange={handleChange}
              />
              <TextField
                style={{ margin: 8 }}
                label='Delivery Fee'
                fullWidth
                value={deliveryFee || ''}
                name='deliveryFee'
                onChange={handleChange}
              />
              <TextField
                style={{ margin: 8 }}
                label='Discount'
                fullWidth
                value={discount || ''}
                name='discount'
                onChange={handleChange}
              />
              <TextField
                style={{ margin: 8 }}
                label='Subtotal'
                fullWidth
                value={subtotal || ''}
                name='subtotal'
                onChange={handleChange}
              />
              <TextField
                style={{ margin: 8 }}
                label='Tax'
                fullWidth
                value={String(tax) || ''}
                name='tax'
                onChange={handleChange}
              />
              <TextField
                style={{ margin: 8 }}
                label='Custom Fee'
                fullWidth
                value={customFee || ''}
                name='customFee'
                onChange={handleChange}
              />
              <TextField
                style={{ margin: 8 }}
                label='Tip'
                fullWidth
                value={tip || ''}
                name='tip'
                onChange={handleChange}
              />
              <TextField
                style={{ margin: 8 }}
                label='Total'
                fullWidth
                value={total || ''}
                name='total'
                onChange={handleChange}
              />
              <Button
                style={{ marginTop: 8 }}
                variant='contained'
                color='info'
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          )}
        </Paper>
      </Grid>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          style={{ fontFamily: 'Lato' }}
          severity='info'
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default OrderForm;
