import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
} from '@mui/material';

const StoreSelectionDialog = ({
  open,
  onClose,
  stores,
  selectedStores,
  onConfirm,
}) => {
  const [tempSelectedStores, setTempSelectedStores] = useState([]);

  useEffect(() => {
    if (open) {
      setTempSelectedStores(selectedStores);
    }
  }, [open, selectedStores]);

  const handleStoreToggle = (store) => {
    setTempSelectedStores((prevSelectedStores) =>
      prevSelectedStores.includes(store)
        ? prevSelectedStores.filter((s) => s !== store)
        : [...prevSelectedStores, store]
    );
  };

  const handleConfirm = () => {
    onConfirm(tempSelectedStores);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Stores to Compare</DialogTitle>
      <DialogContent>
        <List>
          {stores.map((store) => (
            <ListItem key={store.storeId}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempSelectedStores.includes(store)}
                    onChange={() => handleStoreToggle(store)}
                  />
                }
                label={store.storeName}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color='primary'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StoreSelectionDialog;
