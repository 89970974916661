import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, CircularProgress } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

import { useUserAuth } from '../../context/UserAuthContext';
import { CategoryContext } from '../../context/CategoryContext';

import Header from '../../components/Header/Header';
import JotForm from '../../components/JotForm/JotForm';
import Logo from '../../components/Logo/Logo';
import PageTitle from '../../components/PageTitle/PageTitle';

import './Form.css';

const Form = () => {
  const navigate = useNavigate();
  const { categoryId, subcategoryId, jotformId } = useParams();
  const { categoryInfo, updateCategory } = useContext(CategoryContext);
  const { category } = categoryInfo;
  const {
    user: firebaseUser,
    loading: authenticating,
    setSelectedStore,
    selectedStore,
  } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [gettingCategories, setGettingCategories] = useState(false);
  const [stores, setStores] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();
  const [subcategory, setSubcategory] = useState();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
    selectedStore,
  });
  const [showAlert, setShowAlert] = useState('');
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetUserInfo();
      handleGetMessage();
      handleGetStores();
      if (categoryInfo.subcategory) {
        setSubcategory(categoryInfo.subcategory);
      } else {
        handleGetSubCategories();
      }
      handleGetVendorsInfo();
    }
  }, [firebaseUser]);

  const handleGetSubCategories = async () => {
    try {
      setGettingCategories(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/categories/${categoryId}/user=${firebaseUser.email}/list`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.category) {
        const categoryInfo = response.data.category[0].fields;
        updateCategory({
          category: categoryInfo.Name,
          id: categoryId,
          subCategories: categoryInfo.subDepartments,
        });
      }
      if (response.data.subCategories) {
        const sc = response.data.subCategories.find(
          ({ id }) => id === subcategoryId
        );
        setSubcategory(sc.fields.Name);
      }

      setGettingCategories(false);
    } catch (error) {
      console.error('Error fetching subcategory info from db:', error);
      setGettingCategories(false);
    }
  };

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        navigate('/profile');
      } else {
        if (!response.data.userInfo.defaultStore) {
          setFormData({
            ...response.data.userInfo,
            defaultStore: selectedStore
              ? selectedStore
              : response.data.userInfo.selectedStore,
          });
        } else {
          setFormData(response.data.userInfo);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetMessage = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/messages/global`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data.message) {
        setShowAlert(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetStores = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/stores/${firebaseUser.email}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };

  const handleGetVendorsInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/misc/vendor/info`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data) {
        setVendors(response.data);
      } else {
        setVendors([]);
      }
      setLoading(false);
    } catch (error) {
      setVendors([]);
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const changeSelectedVendor = (e) => {
    setSelectedVendor(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const s = stores.find((s) => s.storeId === value);
    setSelectedStore(s.storeId);
    setFormData({
      ...formData,
      [name]: value,
      storeName: s.storeName,
    });
  };
  let content;
  if (authenticating || loading || gettingCategories) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div>
        <PageTitle
          formData={formData}
          handleChange={handleChange}
          stores={stores}
        />
        <JotForm
          changeSelectedVendor={changeSelectedVendor}
          formData={formData}
          jotformId={jotformId}
          category={category}
          subcategory={subcategory}
          email={firebaseUser.email}
          vendors={vendors}
          selectedVendor={selectedVendor}
        />
      </div>
    );
  }
  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='md'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
    </div>
  );
};

export default Form;
