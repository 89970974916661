import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Backdrop,
  Container,
  CircularProgress,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Print as PrintIcon, Email as EmailIcon } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import { useParams, useNavigate } from 'react-router-dom';

import { useUserAuth } from '../../context/UserAuthContext';

import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';
import EmailDialog from '../../components/SendEmailDialog/SendEmailDialog';

const PDFViewer = () => {
  const navigate = useNavigate();

  const {
    user: firebaseUser,
    loading: authenticating,
    selectedStore,
  } = useUserAuth();
  const { exportBatchId, oloInvoiceNumber } = useParams();
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState('');
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
    selectedStore,
  });
  const [invoiceHtml, setInvoiceHTML] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState();

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetUserInfo();
    }
  }, [firebaseUser]);

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        navigate('/profile');
        setLoading(false);
      } else {
        setFormData({
          ...response.data.userInfo,
          defaultStore: selectedStore,
        });
        handleGetOrderInfo();
      }
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const fetchInvoiceHTML = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/olo/invoice/batch=${exportBatchId}/order=${oloInvoiceNumber}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setInvoiceHTML(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to load PDF');
      setLoading(false);
    }
  };

  const handleGetOrderInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/olo/order=${oloInvoiceNumber}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      // Mock order data retrieval based on orderId
      setOrderDetails(response.data);
      fetchInvoiceHTML();
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleSendEmail = async (to, replyTo, subject, body) => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      // Make the API call with axios
      await axios.post(
        `/hd-api/olo/send-email`,
        {
          to,
          replyTo,
          subject,
          body,
          orderId: oloInvoiceNumber,
          exportBatchId,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handlePrint = async () => {
    // Logic to handle printing invoices for the selected invoice
    try {
      setLoading(true);

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      // Request to generate and fetch the merged PDF
      const response = await axios.post(
        `/hd-api/olo/invoice/print`, // Ensure this endpoint triggers the PDF creation and merging
        { invoiceNumber: oloInvoiceNumber },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          responseType: 'blob', // Important to handle the binary data correctly
        }
      );

      // Create a blob URL for the PDF
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      window.open(pdfUrl, '_blank');
      setLoading(false);
    } catch (error) {
      console.error('Failed to print order:', error);
    } finally {
      setLoading(false);
    }
  };

  let content;
  if (authenticating) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div style={{ textAlign: 'center', marginTop: 8 }}>
        <Typography
          style={{ fontFamily: 'Lato', fontSize: 28, marginBottom: 16 }}
        >
          OLO Invoice # {oloInvoiceNumber}
        </Typography>
        <Tooltip title='Print'>
          <IconButton onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Email'>
          <IconButton onClick={handleOpenDialog}>
            <EmailIcon />
          </IconButton>
        </Tooltip>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            marginBottom: '16px',
          }}
        ></div>
        <div
          style={{
            backgroundColor: 'white',
            padding: 32,
            transform: 'scale(0.75)', // Adjust the scale to zoom out
            transformOrigin: 'top',
            overflow: 'auto',
            margin: 'auto',
          }}
          dangerouslySetInnerHTML={{ __html: invoiceHtml }}
        ></div>
      </div>
    );
  }
  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='xl'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
      {isDialogOpen && (
        <EmailDialog
          storeId={orderDetails?.storeId}
          storeName={orderDetails?.storeName}
          invoiceNumber={oloInvoiceNumber}
          open={isDialogOpen}
          onClose={handleCloseDialog}
          emailAddress={orderDetails?.emailAddress}
          confirmSend={handleSendEmail}
        />
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default PDFViewer;
