import React, { useEffect } from 'react';
import { Grid, Container, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from './assets/MendocinoFarms_Logo_Horizontal_RGB_FullColor.png';

import './Redirect.css';

const Redirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(redirectTimeout);
  }, [navigate]);

  return (
    <Container component='main' maxWidth='xs'>
      <div id='background-container'></div>
      <Paper elevation={3} id='paper-container'>
        <img style={{ width: '100%' }} alt='Logo' src={Logo} />
        <Grid container justifyContent='center'>
          <Typography
            style={{ margin: '32px 0 16px', fontFamily: 'Lato' }}
            variant='h5'
          >
            Thank you for your submission!
          </Typography>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Redirect;
