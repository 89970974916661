import React from 'react';
import {
  Box,
  Button,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Slider,
  Typography,
} from '@mui/material';

const drawerWidth = 250;

const Drawer = ({
  radius,
  handleRadiusChange,
  handleDialogToggle,
  handleSearch,
  handleSearchTypeChange,
  handleSortingChange,
  showPreferredCompetitors,
  selectedStores,
  searchType,
  setExcludedTypesDialogOpen,
  setIncludedTypesDialogOpen,
  sortingMethod,
}) => {
  return (
    <Box p={2} width={drawerWidth}>
      <Box mb={1} mt={1}>
        <Button
          style={{ width: '100%', marginBottom: 16 }}
          size='small'
          variant='contained'
          color='primary'
          onClick={handleDialogToggle}
        >
          Your Stores
        </Button>
      </Box>
      <Box mt={2}>
        <Typography variant='h6'>Search Type</Typography>
        <Divider style={{ marginBottom: 8 }} />
        <RadioGroup
          aria-label='sorting-method'
          value={searchType}
          onChange={handleSearchTypeChange}
        >
          {/* <FormControlLabel value='rating' control={<Radio />} label='Rating' /> */}
          <FormControlLabel
            value='nearBy'
            control={<Radio />}
            label='Near By (General)'
          />
          <FormControlLabel
            value='specific'
            control={<Radio />}
            label='Specific Brands'
          />
        </RadioGroup>
      </Box>
      <Box mt={2}>
        <Typography variant='h6'>Filter Options</Typography>
        <Divider />
      </Box>
      <Box mt={2}>
        <Button
          style={{ width: '100%' }}
          size='small'
          color='primary'
          variant='contained'
          disabled={searchType === 'nearBy'}
          onClick={showPreferredCompetitors}
        >
          Select Competitors
        </Button>
      </Box>
      {/* <Box mt={2}>
        <Button
          style={{ width: '100%' }}
          size='small'
          variant='contained'
          color='warning'
          onClick={showIgnoreCompetitors}
        >
          Ignore Competitors
        </Button>
      </Box> */}
      <Box mt={2}>
        <Button
          disabled={searchType === 'specific'}
          style={{ width: '100%' }}
          size='small'
          variant='contained'
          color='primary'
          onClick={() => setIncludedTypesDialogOpen(true)}
        >
          Included Types
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          style={{ width: '100%' }}
          size='small'
          variant='contained'
          color='warning'
          onClick={() => setExcludedTypesDialogOpen(true)}
        >
          Excluded Types
        </Button>
      </Box>
      <Box mt={2}>
        <Typography gutterBottom>Radius (miles): {radius}</Typography>
        <Slider
          value={radius}
          min={1}
          max={20}
          step={1}
          onChange={handleRadiusChange}
          valueLabelDisplay='auto'
        />
      </Box>
      <Box mt={4}>
        <Typography variant='h6'>Sorting Method</Typography>
        <Divider style={{ marginBottom: 8 }} />
        <RadioGroup
          aria-label='sorting-method'
          value={sortingMethod}
          onChange={handleSortingChange}
        >
          {/* <FormControlLabel value='rating' control={<Radio />} label='Rating' /> */}
          <FormControlLabel
            value='distance'
            control={<Radio />}
            label='Distance'
          />
          <FormControlLabel
            disabled={searchType !== 'nearBy'}
            value='popularity'
            control={<Radio />}
            label='Popularity'
          />
          <FormControlLabel
            disabled={searchType === 'nearBy'}
            value='relevance'
            control={<Radio />}
            label='Relevance'
          />
        </RadioGroup>
      </Box>
      <Box mt={2}>
        <Button
          disabled={!selectedStores || selectedStores?.length === 0}
          style={{ width: '100%' }}
          size='small'
          variant='contained'
          color='primary'
          onClick={handleSearch}
        >
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default Drawer;
