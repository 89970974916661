import React from 'react';
import { Alert, Grid } from '@mui/material';

import Image from '../../assets/MendocinoFarms_Logo_Horizontal_RGB_FullColor.png';

const Logo = ({ showAlert, isTabletOrMobile }) => {
  return (
    <div>
      <Grid container justifyContent={'center'} alignContent={'center'}>
        <Grid style={{ textAlign: 'right', marginBottom: 16 }}>
          <img
            style={{ width: '100%', maxWidth: 500 }}
            alt='Logo'
            src={Image}
          />
        </Grid>
      </Grid>
      <Grid container>
        {!isTabletOrMobile && <Grid item xs={3}></Grid>}
        <Grid
          container
          item
          justifyContent={'center'}
          alignContent={'center'}
          xs={12}
        >
          {showAlert && (
            <Alert variant='outlined' severity='warning'>
              {showAlert}
            </Alert>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Logo;
