import React from 'react';
import { Card } from '@mui/material';

import Area from '../Area/Area';

const Section = ({
  answers,
  control,
  errors,
  info,
  section,
  i,
  watch,
  setValue,
}) => {
  const groupedData = {};
  info.forEach((item) => {
    if (!groupedData[item.area]) {
      groupedData[item.area] = [];
    }
    groupedData[item.area].push(item);
  });
  const content = Object.keys(groupedData).map((area, j) => {
    return (
      <Area
        answers={answers}
        control={control}
        errors={errors}
        key={`${section}-${area}`}
        info={groupedData[area]}
        section={section}
        i={i}
        j={j}
        setValue={setValue}
        watch={watch}
      />
    );
  });
  return (
    <Card
      style={{
        height: '100%',
        // maxHeight: 600,
        // overflowY: 'scroll'
      }}
    >
      {content}
    </Card>
  );
};

export default Section;
