import React from 'react';
import { Card, CardContent, Typography, Grid, Container } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { format } from 'date-fns';

// const metrics = [
//   {
//     title: 'Catering Sales',
//     amount: '$100.00',
//     percentageChange: 5.5, // Positive
//   },
//   {
//     title: 'Catering Orders',
//     amount: '10',
//     percentageChange: -2.3, // Negative
//   },
// ];

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const MetricsDisplay = ({ lastUpdated, metrics }) => {
  const today = format(new Date(), 'MMMM d, yyyy');
  return (
    <Container maxWidth='md'>
      <Card style={{ borderRadius: 8 }}>
        <CardContent>
          <Typography
            style={{
              fontFamily: 'Lato',
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 16,
            }}
          >
            {today}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Lato',
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
              >
                <NoMaxWidthTooltip
                  title={
                    <label style={{ fontSize: 16 }}>
                      Catering Sales = Total Amount - Delivery Fee - Tips - Tax
                    </label>
                  }
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [150, -5],
                          },
                        },
                      ],
                    },
                  }}
                >
                  Catering Sales*
                </NoMaxWidthTooltip>
              </Typography>

              <Typography
                style={{ fontFamily: 'Lato', fontSize: 24, marginTop: 4 }}
              >
                ${numberWithCommas(metrics.sales)}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontSize: 12,
                  marginTop: 4,
                  color: metrics.salesDiff
                    ? metrics.salesDiff >= 0
                      ? 'green'
                      : 'red'
                    : 'gray',
                  fontWeight: 'bold',
                }}
              >
                {metrics.salesDiff
                  ? `${metrics.salesDiff >= 0 ? '+' : ''}${numberWithCommas(
                      metrics.salesDiff.toFixed(2)
                    )} % vs yesterday`
                  : 'No Sales Yesterday'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
              >
                Catering Orders
              </Typography>
              <Typography
                style={{ fontFamily: 'Lato', fontSize: 24, marginTop: 4 }}
              >
                {numberWithCommas(metrics.orders)}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontSize: 12,
                  marginTop: 4,
                  color: metrics.ordersDiff
                    ? metrics.ordersDiff >= 0
                      ? 'green'
                      : 'red'
                    : 'gray',
                  fontWeight: 'bold',
                }}
              >
                {metrics.yesterdayTotalOrders === 0
                  ? 'No Orders Yesterday'
                  : metrics.ordersDiff === 0
                  ? 'Equal to Yesterday'
                  : metrics.ordersDiff
                  ? `${metrics.ordersDiff >= 0 ? '+' : ''}${numberWithCommas(
                      metrics.ordersDiff.toFixed(2)
                    )} % vs yesterday`
                  : 'No Sales Yesterday'}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            style={{
              fontFamily: 'Lato',
              fontSize: 12,
              textAlign: 'center',
              marginTop: 12,
            }}
          >
            Updated: {lastUpdated}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default MetricsDisplay;
