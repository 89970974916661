import React from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const MainContent = ({
  authenticating,
  loading,
  competitors,
  selectedStores,
  handleClickedChip,
  handleDeleteChip,
  handleDialogToggle,
  highlightedStore,
  nextPageToken,
  handleLoadMore,
}) => {
  let content;
  if (authenticating || loading) {
    content = (
      <div
        style={{
          marginTop: '10%',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    const storeName = selectedStores.find(
      ({ storeId }) => storeId === highlightedStore
    );
    content =
      selectedStores.length > 0 ? (
        <>
          <br />
          <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Store</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Distance (miles)</TableCell>
                  <TableCell>Rating Score</TableCell>
                  <TableCell>Rating Count</TableCell>
                  <TableCell>Google Maps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {competitors.map((restaurant, index) => (
                  <TableRow key={index}>
                    <TableCell>{storeName?.storeName}</TableCell>
                    <TableCell>{restaurant.name}</TableCell>

                    <TableCell>{restaurant.formattedAddress}</TableCell>
                    <TableCell>{restaurant.distance}</TableCell>
                    <TableCell>{restaurant.rating}</TableCell>
                    <TableCell>{restaurant.userRatingCount}</TableCell>
                    <TableCell>
                      <a
                        href={restaurant.googleMapsUri}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Link
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {nextPageToken && (
            <Box display='flex' justifyContent='center' mt={2}>
              <Button onClick={handleLoadMore}>Load More</Button>
            </Box>
          )}
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography mt={4} style={{ fontFamily: 'Lato', fontSize: 24 }}>
            Please select a store to begin
          </Typography>
          <Button
            style={{ marginTop: 12 }}
            size='large'
            variant='contained'
            color='primary'
            onClick={handleDialogToggle}
          >
            Select Your Stores
          </Button>
        </div>
      );
  }
  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,
        p: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography style={{ fontFamily: 'Lato', fontSize: 34 }}>
        Competitor Comparison
      </Typography>
      {selectedStores.length > 0 && (
        <Box mt={4} sx={{ width: '100%', marginTop: '16px !important' }}>
          <Box mt={2} display='flex' flexWrap='wrap' gap={1}>
            {selectedStores.map((store) => (
              <Chip
                key={store.storeId}
                label={store.storeName}
                onClick={() => handleClickedChip(store)}
                onDelete={handleDeleteChip(store)}
                color={highlightedStore === store.storeId ? 'info' : 'primary'}
              />
            ))}
          </Box>
        </Box>
      )}
      {content}
    </Box>
  );
};

export default MainContent;
