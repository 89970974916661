import React, { createContext, useState } from 'react';

const CategoryContext = createContext();

const CategoryContextProvider = ({ children }) => {
  const [categoryInfo, setCategoryInfo] = useState({});
  const [subcategories, setSubcategories] = useState([]);

  const updateCategory = (newCategory) => {
    setCategoryInfo(newCategory);
  };
  const updateSubcategories = (newSubcategories) => {
    setSubcategories(newSubcategories);
  };

  return (
    <CategoryContext.Provider
      value={{
        categoryInfo,
        subcategories,
        updateCategory,
        updateSubcategories,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export { CategoryContext, CategoryContextProvider };
