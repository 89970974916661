import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
} from '@mui/material';
import GoogleButton from 'react-google-button';
import { useUserAuth } from '../../context/UserAuthContext';
import Logo from '../../assets/MendocinoFarms_Logo_Horizontal_RGB_FullColor.png';

import './Login.css';

const styles = {
  form: {
    width: '100%',
    marginTop: 4,
  },
  submit: {
    margin: 4,
  },
  googleButton: {
    margin: 36,
  },
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { logIn, googleSignIn, loading } = useUserAuth();
  const navigate = useNavigate();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError('');
  //   try {
  //     await logIn(email, password);
  //     navigate('/home');
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate('/home');
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <div id='background-container'></div>
      <Paper elevation={3} id='paper-container'>
        <img style={{ width: '100%' }} alt='Logo' src={Logo} />
        <Typography style={{ marginTop: 20, fontFamily: 'Lato' }} variant='h5'>
          Support Happy
        </Typography>
        {error && <Typography color='error'>{error}</Typography>}
        <Grid container justifyContent='center'>
          <Grid item style={styles.googleButton}>
            {loading ? (
              <CircularProgress />
            ) : (
              <GoogleButton onClick={handleGoogleSignIn} type='dark' />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Login;
