import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Backdrop,
  Container,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useMediaQuery } from 'react-responsive';

import { useUserAuth } from '../../context/UserAuthContext';

import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';
import OrderForm from '../../components/OrderForm/OrderForm';

import './OLOInvoiceAdjustment.css';

const Main = () => {
  const { user: firebaseUser, loading: authenticating } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});
  const [showAlert, setShowAlert] = useState('');
  const [originalOrderDetails, setOriginalOrderDetails] = useState({});
  const [searchOrderId, setSearchOrderId] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetMessage();
    }
  }, [firebaseUser]);

  const handleGetMessage = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/messages/global`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data.message) {
        setShowAlert(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/olo/order=${searchOrderId}`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      // Mock order data retrieval based on orderId
      setOrderDetails(response.data);
      setOriginalOrderDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (JSON.stringify(orderDetails) === JSON.stringify(originalOrderDetails)) {
      setSnackbarMessage('No changes detected.');
      setShowSnackbar(true);
    } else {
      console.log('Updated Order Details:', orderDetails);
      await updateNetsuiteRecord();
      setSnackbarMessage('Changes saved successfully.');
      setShowSnackbar(true);
      // Logic to save updated order details
      // You can send orderDetails to your server or handle as needed
    }
  };

  const updateNetsuiteRecord = async () => {
    try {
      setLoading(true);
      const { netsuiteInternalId, orderId, oloExportbatchId } = orderDetails;
      const changes = {};
      Object.keys(orderDetails).forEach((key) => {
        if (orderDetails[key] !== originalOrderDetails[key]) {
          changes[key] = orderDetails[key];
        }
      });
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      await axios.patch(
        `/hd-api/netsuite/order`,
        {
          orderDetails: {
            ...changes,
            netsuiteInternalId,
            orderId,
            oloExportbatchId,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setLoading(false);
    } catch (error) {
      console.error('Error updating netsuite record:', error);
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleChangeSearchOrderId = (e) => {
    const { value } = e.target;
    setSearchOrderId(value);
  };

  let content;
  if (authenticating) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div style={{ textAlign: 'center', marginTop: 8 }}>
        <Typography variant='h4' style={{ fontFamily: 'Lato' }}>
          OLO Invoice Adjustment
        </Typography>
        <OrderForm
          handleSave={handleSave}
          handleSearch={handleSearch}
          handleChange={handleChange}
          handleChangeSearchOrderId={handleChangeSearchOrderId}
          searchOrderId={searchOrderId}
          orderDetails={orderDetails}
          showSnackbar={showSnackbar}
          handleSnackbarClose={handleSnackbarClose}
          snackbarMessage={snackbarMessage}
        />
      </div>
    );
  }
  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='md'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default Main;
