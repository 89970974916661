import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Grid,
  IconButton,
  Container,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import CloseIcon from '@mui/icons-material/Close';

import { useUserAuth } from '../../context/UserAuthContext';
import UserProfileForm from '../../components/ProfileForm/ProfileForm';

import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';

import './EditProfile.css';

const EditProfile = () => {
  const navigate = useNavigate();
  const { user: firebaseUser, loading: authenticating } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '',
  });
  const [stores, setStores] = useState([]);
  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState('');
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetStores();
      handleGetUserInfo();
      handleGetMessage();
    }
  }, [firebaseUser]);

  useEffect(() => {
    if (firebaseUser?.displayName) {
      // Parse fullName and set firstName and lastName in state
      const [firstName, lastName] = firebaseUser.displayName.split(' ');
      setFormData({
        ...formData,
        firstName,
        lastName,
      });
    }
  }, [firebaseUser.displayName]);

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        const [firstName, lastName] = firebaseUser.displayName.split(' ');
        setFormData({
          ...formData,
          firstName,
          lastName,
          role: 'Store',
        });
      } else {
        setFormData(response.data.userInfo);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetMessage = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/messages/global`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data.message) {
        setShowAlert(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetStores = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/stores/${firebaseUser.email}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };

  const handleCreateUser = async () => {
    try {
      setLoading(true);

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      await axios.post(
        `/hd-api/user/create`,
        {
          ...formData,
          emailAddress: firebaseUser.email,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      navigate('/home');
      setLoading(false);
    } catch (error) {
      console.error('Error fetching StoreInfo from db:', error);
      setLoading(false);
    }
  };

  const handleUpdateUser = async () => {
    try {
      setLoading(true);

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      await axios.put(
        `/hd-api/user/update`,
        {
          ...formData,
          emailAddress: firebaseUser.email,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      navigate('/home');
      setLoading(false);
    } catch (error) {
      console.error('Error fetching StoreInfo from db:', error);
      setLoading(false);
    }
  };

  let content;
  if (authenticating || loading) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <Paper elevation={3} id='default-store-form-container'>
        <Grid container spacing={1}>
          <Grid justifyContent='center' container item xs={2} sm={4}></Grid>
          <Grid justifyContent='center' container item xs={8} sm={4}>
            <Typography style={{ fontFamily: 'Lato' }} variant='h5'>
              User Profile
            </Typography>
          </Grid>
          <Grid
            style={{ paddingTop: 0 }}
            justifyContent='flex-end'
            container
            item
            xs={2}
            sm={4}
          >
            {formData.userId && (
              <IconButton onClick={() => navigate(-1)} aria-label='delete'>
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>

        {error && <Typography color='error'>{error}</Typography>}
        <div style={{ width: '100%', maxWidth: 600, margin: 16 }}>
          <UserProfileForm
            formData={formData}
            handleCreateUser={handleCreateUser}
            handleUpdateUser={handleUpdateUser}
            setFormData={setFormData}
            stores={stores}
          />
        </div>
      </Paper>
    );
  }
  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='md'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
    </div>
  );
};

export default EditProfile;
