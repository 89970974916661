import React, { useState } from 'react';
import axios from 'axios';
import { Grid, Button, Container, CircularProgress } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useUserAuth } from '../../context/UserAuthContext';
import Logo from '../../assets/MendocinoFarms_Logo_Horizontal_RGB_FullColor.png';
import './UploadHTML.css';
import { useNavigate } from 'react-router-dom';

const UploadHTML = (props) => {
  const { logOut, user: firebaseUser, loading: authenticating } = useUserAuth();
  const navigate = useNavigate();
  const [showUserRegistration, setShowUserRegistration] = useState(false);
  const [file, setFile] = useState(null);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleFileUpload = async () => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append('htmlFile', file);

      // Use Axios to send a POST request to your server
      await axios.post('/hd-api/upload-html', formData, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      navigate('/home');
      // You can handle the response from the server here
    } catch (error) {
      console.error('Error uploading HTML file:', error);
    }
  };

  let content;
  if (authenticating || !firebaseUser.getIdToken) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <Grid
        container
        item
        justifyContent={'center'}
        alignContent={'center'}
        xs={12}
      >
        <input
          type='file'
          accept='.html'
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id='htmlFileInput'
        />
        <label htmlFor='htmlFileInput'>
          <Button
            variant='contained'
            color='primary'
            component='span'
            style={{ fontFamily: 'Lato', marginTop: 16 }}
          >
            Upload HTML File
          </Button>
        </label>
        {file && (
          <Button
            variant='contained'
            color='info'
            style={{ fontFamily: 'Lato', marginTop: 16, marginLeft: 8 }}
            onClick={handleFileUpload}
          >
            Submit
          </Button>
        )}
      </Grid>
    );
  }
  return (
    <div>
      <Container maxWidth='xl'>
        <Grid
          container
          justifyContent={'flex-end'}
          alignContent={'center'}
          xs={12}
        >
          <Grid
            justifyContent={'flex-end'}
            alignContent={'center'}
            container
            item
            style={{ marginTop: 16 }}
          >
            <Button
              type='submit'
              variant='contained'
              color='info'
              style={{ fontFamily: 'Lato' }}
              onClick={() => setShowUserRegistration(true)}
              size='small'
            >
              {isTabletOrMobile ? <ManageAccountsIcon /> : 'Edit Profile'}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth='md'>
        <Grid
          container
          justifyContent={'center'}
          alignContent={'center'}
          xs={12}
        >
          <Grid style={{ textAlign: 'right', marginBottom: 16 }} xs={10}>
            <img style={{ width: '100%' }} alt='Logo' src={Logo} />
          </Grid>
        </Grid>
        <div id='main'></div>
        {content}
      </Container>
    </div>
  );
};

export default UploadHTML;
