import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, CircularProgress } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

import { useUserAuth } from '../../context/UserAuthContext';
import RestaurantForm from '../../components/RestaurantForm/RestaurantForm';
import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';

import './ChangeStore.css';

const ChangeStore = () => {
  const navigate = useNavigate();
  const { user: firebaseUser, loading: authenticating } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
  });
  const [showAlert, setShowAlert] = useState('');
  const [stores, setStores] = useState([]);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetStores();
      handleGetMessage();
    }
  }, [firebaseUser]);

  const handleGetStores = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/stores/${firebaseUser.email}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };

  const handleSetDefaultStore = async () => {
    try {
      setLoading(true);

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      await axios.post(
        `/hd-api/user/default/${firebaseUser.email}`,
        {
          defaultStore: defaultStore,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      navigate(-1);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching StoreInfo from db:', error);
      setLoading(false);
    }
  };

  const handleGetMessage = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/messages/global`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data.message) {
        setShowAlert(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const { defaultStore } = formData;
  let content;
  if (authenticating || loading) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <RestaurantForm
        formData={formData}
        defaultStore={defaultStore}
        stores={stores}
        setFormData={setFormData}
        handleSetDefaultStore={handleSetDefaultStore}
      />
    );
  }
  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} disable={true} />
      <Container maxWidth='md'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
    </div>
  );
};

export default ChangeStore;
