import React, { useState } from 'react';
import axios from 'axios';
import {
  IconButton,
  FormControl,
  Grid,
  InputLabel,
  InputBase,
  NativeSelect,
  Paper,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PrintDialog from './PrintDialog';
import PrintIcon from '@mui/icons-material/Print';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ExportIcon from '@mui/icons-material/FileDownload';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
    maxWidth: 'unset',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const FilterActionBar = ({
  activeTable,
  firebaseUser,
  searchField,
  handleChangeSearchField,
  searchInput,
  handleSearch,
  handleClear,
  handleExport,
  handleToggleAcknowledged,
  selectedOrders,
  showStoreFilter,
  selectedHandoffType,
  setSelectedHandoffType,
  selectedOloStore,
  handleChangeSelectedStore,
  stores,
}) => {
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpenPrintDialog = () => {
    setPrintDialogOpen(true);
  };

  const handlePrint = async (storeId, startDate, handleClose) => {
    // Logic to handle printing invoices for the selected date range
    console.log('Printing invoices for date range:', startDate);
    try {
      setLoading(true);

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      // Request to generate and fetch the merged PDF
      const response = await axios.post(
        `/hd-api/olo/invoice/print`, // Ensure this endpoint triggers the PDF creation and merging
        { startDate, selectedOloStore: storeId },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          responseType: 'blob', // Important to handle the binary data correctly
        }
      );

      // Create a blob URL for the PDF
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      window.open(pdfUrl, '_blank');
      handleClose();
    } catch (error) {
      console.error('Failed to print orders:', error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Paper style={{ backgroundColor: '#f8f8f8' }}>
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='center'
        style={{ margin: '24px 0 0 0' }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '16px',
            paddingBottom: 12,
          }}
        >
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [70, 20],
                    },
                  },
                ],
              },
            }}
            title={
              activeTable && activeTable === 'acknowledged'
                ? 'Mark Unacknowledged'
                : 'Mark Acknowledged'
            }
          >
            <span>
              <IconButton
                color='info'
                onClick={handleToggleAcknowledged}
                disabled={Object.values(selectedOrders).every(
                  (isSelected) => !isSelected
                )}
              >
                {activeTable && activeTable === 'acknowledged' ? (
                  <MarkEmailUnreadIcon />
                ) : (
                  <MarkEmailReadIcon />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={9}
          lg={8}
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            paddingBottom: 12,
          }}
        >
          {showStoreFilter && (
            <FormControl size='small'>
              <InputLabel id='store-label'>Store</InputLabel>
              <NativeSelect
                value={selectedOloStore}
                onChange={handleChangeSelectedStore}
                label='Store'
                input={<BootstrapInput />}
              >
                {stores.map(({ storeId, storeName }) => (
                  <option key={storeId} value={storeId}>
                    {storeName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
          {showStoreFilter && (
            <FormControl size='small'>
              <InputLabel id='store-label2' style={{ maxWidth: 'unset' }}>
                Delivery Method
              </InputLabel>
              <NativeSelect
                value={selectedHandoffType}
                onChange={(e) => setSelectedHandoffType(e.target.value)}
                label='Delivery Method'
                input={<BootstrapInput />}
              >
                <option value='all'>All</option>
                <option value='delivery'>Delivery</option>
                <option value='pickup'>Pickup</option>
              </NativeSelect>
            </FormControl>
          )}
          <FormControl size='small'>
            <InputLabel id='search-field-label'>Search Field</InputLabel>
            <NativeSelect
              value={searchField}
              onChange={handleChangeSearchField}
              label='Search Field'
              input={<BootstrapInput />}
            >
              <option value='orderNumber'>Order Number</option>
              <option value='timeWanted'>Time Wanted</option>
              <option value='timePlaced'>Time Placed</option>
              <option value='name'>Booking Client Name</option>
              <option value='email'>Booking Client Email</option>
              <option value='phoneNumber'>Phone</option>
              <option value='total'>Total</option>
              <option value='tax'>Tax</option>
              <option value='tip'>Tip</option>
            </NativeSelect>
          </FormControl>
          {searchInput}
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [250, 20],
                    },
                  },
                ],
              },
            }}
            title='Search'
          >
            <IconButton onClick={handleSearch} color='primary'>
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [265, 20],
                    },
                  },
                ],
              },
            }}
            title='Reset'
          >
            <IconButton onClick={handleClear} color='info'>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            paddingBottom: 12,
            paddingRight: 32,
          }}
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
        >
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [450, 20],
                    },
                  },
                ],
              },
            }}
            title='Print Invoices'
          >
            <IconButton onClick={handleOpenPrintDialog} color='default'>
              <PrintIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [450, 20],
                    },
                  },
                ],
              },
            }}
            title='Export to XLSX'
          >
            <IconButton onClick={handleExport} color='default'>
              <ExportIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <PrintDialog
          stores={stores}
          selectedOloStore={selectedOloStore}
          loading={loading}
          open={printDialogOpen}
          onClose={() => setPrintDialogOpen(false)}
          onPrint={handlePrint}
        />
      </Grid>
    </Paper>
  );
};

export default FilterActionBar;
