import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Checkbox,
  Button,
} from '@mui/material';

const OrderTable = ({
  orders,
  order,
  orderBy,
  handleRequestSort,
  onRowClick,
  onHeaderCheckboxChange,
  selectedOrders,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPage,
  page,
  columnMap,
  goghUser,
  handleViewInvoice,
  acknowledged,
  stableSort,
  getComparator,
}) => {
  const handleSortRequest = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const sortedRows = stableSort(orders, getComparator(order, orderBy));
  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox
                indeterminate={
                  Object.values(selectedOrders).some(Boolean) &&
                  !Object.values(selectedOrders).every(Boolean)
                }
                checked={
                  Object.values(selectedOrders).length > 0 &&
                  Object.values(selectedOrders).every(Boolean)
                }
                onChange={onHeaderCheckboxChange}
              />
            </TableCell>
            {[
              'oloRef',
              'storeName',
              'timeWanted',
              'timePlaced',
              'name',
              'emailAddress',
              'phoneNumber',
              ...(goghUser ? ['deliveryAddress', 'deliveryZipcode'] : []),
              'total',
              'tax',
              'tip',
            ].map((column) => (
              <TableCell key={column}>
                <TableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? order : 'asc'}
                  onClick={handleSortRequest(column)}
                >
                  {columnMap[column]}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((orderData, index) => (
              <TableRow
                key={index}
                selected={selectedOrders[orderData.oloRef]}
                onClick={() => onRowClick(orderData.oloRef)}
                sx={{
                  fontWeight: acknowledged ? 'normal' : 'bold',
                  backgroundColor: acknowledged ? '#f0f0f0' : 'inherit',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: acknowledged ? '#e0e0e0' : '#f5f5f5',
                  },
                }}
              >
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedOrders[orderData.oloRef] || false}
                  />
                </TableCell>
                <TableCell align='left'>
                  <Button
                    onClick={() =>
                      handleViewInvoice(
                        orderData.exportBatchNumber,
                        orderData.oloRef
                      )
                    }
                  >
                    {orderData.oloRef}
                  </Button>
                </TableCell>
                <TableCell>{orderData.storeName}</TableCell>
                <TableCell>{orderData.timeWanted}</TableCell>
                <TableCell>{orderData.timePlaced}</TableCell>
                <TableCell>{orderData.name}</TableCell>
                <TableCell>{orderData.emailAddress}</TableCell>
                <TableCell>{orderData.phoneNumber}</TableCell>
                {goghUser && (
                  <>
                    <TableCell>{orderData.deliveryAddress}</TableCell>
                    <TableCell>{orderData.deliveryZipcode}</TableCell>
                  </>
                )}
                <TableCell>${Number(orderData.total).toFixed(2)}</TableCell>
                <TableCell>${Number(orderData.tax).toFixed(2)}</TableCell>
                <TableCell>${Number(orderData.tip).toFixed(2)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component='div'
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default OrderTable;
