import React from 'react';
import { Controller } from 'react-hook-form';

import {
  Grid,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

import './Questions.css';

const Questions = ({ control, errors, section, area, questions }) => {
  const content = questions.map(({ question, id }, i) => {
    let questionStyle = {
      backgroundColor: '#e5eaf4',
      padding: 8,
      borderLeft: '1px solid',
      borderLeftColor: '#c3cad8',
      borderTop: '1px solid',
      borderTopColor: '#c3cad8',
      borderRadius: i === 0 ? '4px 0 0' : '',
      textAlign: 'left',
    };
    let yesNoLeft = {
      border: '1px solid',
      borderTop: '1px solid #c3cad8',
      borderBottom: 'none',
      borderColor: '#c3cad8',
      borderLeft: '1px solid #c3cad8',
      borderRight: '1px solid #c3cad8',
      textAlign: 'center',
    };
    let yesNoRight = {
      border: '1px solid',
      borderTop: '1px solid #c3cad8',
      borderBottom: 'none',
      borderColor: '#c3cad8',
      borderLeft: 'none',
      borderRight: '1px solid #c3cad8',
      textAlign: 'center',
    };

    if (i + 1 === questions.length) {
      questionStyle = {
        backgroundColor: '#e5eaf4',
        padding: 8,
        borderLeft: '1px solid',
        borderLeftColor: '#c3cad8',
        borderTop: '1px solid',
        borderTopColor: '#c3cad8',
        borderBottom: '1px solid',
        borderBottomColor: '#c3cad8',
        borderRadius: '0 0 0 4px',
        textAlign: 'left',
      };
      yesNoLeft = {
        border: '1px solid',
        borderTop: '1px solid #c3cad8',
        borderBottom: '1px solid #c3cad8',
        borderColor: '#c3cad8',
        borderLeft: '1px solid #c3cad8',
        borderRight: '1px solid #c3cad8',
        textAlign: 'center',
      };
      yesNoRight = {
        border: '1px solid',
        borderTop: '1px solid #c3cad8',
        borderBottom: '1px solid #c3cad8',
        borderColor: '#c3cad8',
        borderLeft: 'none',
        borderRight: '1px solid #c3cad8',
        borderRadius: '0 0 4px 0',
        textAlign: 'center',
      };
    }
    const name = `${section + 1}-${area + 1}-${id}`;
    return (
      <Grid container key={id}>
        <Grid
          style={questionStyle}
          item
          container
          xs={8}
          alignContent={'center'}
        >
          <Typography
            color={errors[name]?.message ? 'error' : 'default'}
            style={{ fontFamily: 'Lato', fontSize: '.75em' }}
          >
            {question}*
          </Typography>
        </Grid>
        <Grid item container xs={4} justifyContent={'center'}>
          <Controller
            rules={{
              required: {
                value: true,
                message: 'An answer is required for this field',
              },
            }}
            control={control}
            name={name}
            render={({ field }) => (
              <RadioGroup
                {...field}
                value={field.value ? field.value : ''}
                style={{ width: '100%' }}
                row
              >
                <Grid style={yesNoLeft} item xs={6}>
                  <FormControlLabel
                    value='1'
                    control={<Radio />}
                    style={{ margin: '4px 0 4px 4px' }}
                  />
                </Grid>
                <Grid style={yesNoRight} item xs={6}>
                  <FormControlLabel
                    value='0'
                    control={<Radio />}
                    style={{ margin: '4px 0 4px 4px' }}
                  />
                </Grid>
              </RadioGroup>
            )}
          />
        </Grid>
      </Grid>
    );
  });
  return (
    <Grid style={{ fontWeight: 'normal', marginTop: 16 }} container>
      <Grid container justifyContent='flex-end'>
        <Grid
          style={{
            backgroundColor: '#e5eaf4',
            padding: 12,
            borderLeft: '1px solid',
            borderLeftColor: '#c3cad8',
            borderRight: '1px solid',
            borderRightColor: '#c3cad8',
            borderTop: '1px solid',
            borderTopColor: '#c3cad8',
            // borderBottom: '1px solid',
            // borderBottomColor: '#c3cad8',
            borderRadius: '4px 0 0',
            textAlign: 'center',
            fontFamily: 'Lato',
            fontSize: '.75em',
          }}
          item
          xs={2}
        >
          Yes
        </Grid>
        <Grid
          style={{
            backgroundColor: '#e5eaf4',
            padding: 12,
            borderRight: '1px solid',
            borderRightColor: '#c3cad8',
            borderTop: '1px solid',
            borderTopColor: '#c3cad8',
            // borderBottom: '1px solid',
            // borderBottomColor: '#c3cad8',
            borderRadius: '0 4px 0 0',
            textAlign: 'center',
            fontFamily: 'Lato',
            fontSize: '.75em',
          }}
          item
          xs={2}
        >
          No
        </Grid>
      </Grid>
      {content}
    </Grid>
  );
};

export default Questions;
