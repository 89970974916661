import React, { useEffect, useState, createRef } from 'react';
import { Controller } from 'react-hook-form';
import {
  Divider,
  MenuItem,
  Select,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Questions from '../Questions/Questions';

const Area = ({ control, errors, info, i, j, section, setValue, watch }) => {
  const [showDetail, setShowDetail] = useState(false);
  const groupedData = {};

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const keys = Object.keys(value);
      for (let index = 0; index < keys.length; index++) {
        const element = value[keys[index]];
        if (
          keys[index].split('-').slice(0, -1).join('-') === `${i + 1}-${j + 1}`
        ) {
          if (element === '0') {
            // console.log('showing detail');
            setShowDetail(true);
            break;
          } else if (element === '1') {
            // console.log('hiding');
            setShowDetail(false);
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const dropzoneRef = createRef();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleDrop = (acceptedFiles) => {
    const filesAsBlobs = acceptedFiles.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsArrayBuffer(file);
      });
    });

    Promise.all(filesAsBlobs).then((blobArrayBuffers) => {
      const files = blobArrayBuffers.map((buffer, index) => {
        return new File(
          [buffer],
          String(`${i + 1}-${j + 1}-${acceptedFiles[index].name}`),
          {
            type: acceptedFiles[index].type,
          }
        );
      });
      setValue(`${i + 1}-${j + 1}-files`, files);
    });
  };

  info.forEach((item) => {
    if (!groupedData[item.area]) {
      groupedData[item.area] = [];
    }
    groupedData[item.area].push(item);
  });

  const content = Object.keys(groupedData).map((area) => {
    const name = `${i + 1}-${j + 1}`;
    return (
      <div key={`${section}-${area}`}>
        <div
          style={{
            padding: 16,
            fontFamily: 'Lato',
            fontSize: 16,
          }}
        >
          {i + 1}.{j + 1} - {area}
          <Questions
            control={control}
            errors={errors}
            questions={groupedData[area]}
            section={i}
            area={j}
          />
        </div>
        {showDetail ? (
          <Grid style={{ padding: 20 }} item xs={12} sm={6} md={4} lg={4}>
            <Typography style={{ fontFamily: 'Lato', fontSize: 16 }}>
              {i + 1}.{j + 1} - Explain the issue(s)
            </Typography>
            <Controller
              rules={{
                required: showDetail
                  ? {
                      value: true,
                      message: 'An answer is required for this field',
                    }
                  : false,
              }}
              name={`${name}-explain`}
              control={control}
              render={({ field }) => (
                <TextField
                  error={errors[`${name}-explain`] ? true : false}
                  helperText={String(errors[`${name}-explain`]?.message ?? '')}
                  id='outlined-textarea'
                  multiline
                  minRows={8}
                  style={{ width: '100%', margin: '8px 0' }}
                  {...field}
                />
              )}
            />
            <Typography style={{ fontFamily: 'Lato', fontSize: 12 }}>
              If multiple problems exist, please give an explanation and picture
              for each.
            </Typography>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontSize: 16,
                marginTop: 44,
              }}
            >
              {i + 1}.{j + 1} - Who is responsible?
            </Typography>
            <Controller
              rules={{
                required: showDetail
                  ? {
                      value: true,
                      message: 'An answer is required for this field',
                    }
                  : false,
              }}
              name={`${name}-responsible`}
              control={control}
              render={({ field }) => (
                <FormControl>
                  <Select
                    error={errors[`${name}-responsible`] ? true : false}
                    {...field}
                    defaultValue={0}
                    style={{ minWidth: 200, marginTop: 8 }}
                    value={field.value ? field.value : 0}
                  >
                    <MenuItem value={0}>
                      <em>Please select</em>
                    </MenuItem>
                    <MenuItem value='Facilities'>Facilities</MenuItem>
                    <MenuItem value='Store'>Store</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: '#d32f2f' }}>
                    {String(errors[`${name}-responsible`]?.message ?? '')}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <Typography
              style={{
                fontFamily: 'Lato',
                fontSize: 16,
                marginTop: 44,
              }}
            >
              {i + 1}.{j + 1} - Image(s)
            </Typography>
            <Controller
              name={`${name}-files`}
              control={control}
              render={({ field }) => (
                <Dropzone
                  onDrop={handleDrop}
                  ref={dropzoneRef}
                  noClick
                  noKeyboard
                >
                  {({ getRootProps, getInputProps }) => {
                    let v = (
                      <div>
                        <CloudUploadIcon fontSize='large' color='primary' />
                        <Typography
                          style={{
                            fontFamily: 'Lato',
                            fontSize: 20,
                          }}
                        >
                          Browse Files
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: 'Lato',
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                        >
                          Drag and drop files here
                        </Typography>
                      </div>
                    );
                    if (field && field.value) {
                      if (Array.isArray(field.value)) {
                        v = field.value.map((f) => (
                          <div key={f.name}>
                            <label>{f.name}</label>
                            <br />
                          </div>
                        ));
                      } else {
                        v = <div>{field.value}</div>;
                      }
                    }
                    return (
                      <section
                        onClick={openDialog}
                        style={{
                          marginTop: 8,
                          height: 150,
                          border: '1px dashed black',
                          borderRadius: 4,
                          backgroundColor: '#f3f3fe',
                        }}
                      >
                        <div
                          {...getRootProps({ className: 'dropzone' })}
                          style={{ textAlign: 'center', padding: 28 }}
                        >
                          <input
                            {...getInputProps({
                              id: 'spreadsheet',
                              onChange: field.onChange,
                              onBlur: field.onBlur,
                              multiple: true, // Allow multiple files
                            })}
                          />
                          {v}
                        </div>
                      </section>
                    );
                  }}
                </Dropzone>
              )}
            />
          </Grid>
        ) : null}
      </div>
    );
  });

  //   const files = acceptedFiles.map((file) => (
  //     <li key={file.path}>
  //       {file.path} - {file.size} bytes
  //     </li>
  //   ));

  return (
    <div>
      {content}
      <Divider style={{ marginTop: 16 }} />
    </div>
  );
};

export default Area;
