import React from 'react';
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const StoreSelector = ({
  handleSaveSurvey,
  stores,
  formData,
  handleChange,
}) => {
  let text = 'No store selected';
  if (formData.defaultStore) {
    text = 'Store';
  }
  return (
    <Grid
      style={{
        marginTop: 16,
        marginBottom: 8,
      }}
      justifyContent={'center'}
      container
      xs={12}
      spacing={1}
    >
      {/* <Grid item xs={0} md={4}></Grid> */}
      {/* <Grid item xs={8} md={4}> */}
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <InputLabel>{text}</InputLabel>
          <Select
            disabled={stores?.length === 0}
            value={formData.defaultStore}
            onChange={handleChange}
            label={text}
            name='defaultStore'
            style={{
              background: 'white',
              textAlign: 'left',
            }}
          >
            {stores?.map((store, i) =>
              i === 0 ? (
                <MenuItem key={store.storeId} value={store.storeId}>
                  {store.storeName}
                </MenuItem>
              ) : (
                <MenuItem key={store.storeId} value={store.storeId}>
                  {store.storeName}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid
        container
        item
        xs={5}
        md={4}
        alignContent={'center'}
        justifyContent='flex-start'
        spacing={1}
      >
        {handleSaveSurvey && (
          <Grid item>
            <Button
              disabled={!formData || !formData.defaultStore}
              variant='contained'
              size='small'
              style={{ margin: 4, fontFamily: 'Lato' }}
              color='info'
            >
              Start New
            </Button>
            <Button
              onChange={handleSaveSurvey}
              type='save'
              disabled={!formData || !formData.defaultStore}
              variant='contained'
              size='small'
              style={{ margin: 4, fontFamily: 'Lato' }}
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid> */}
    </Grid>
  );
};

export default StoreSelector;
