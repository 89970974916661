import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AlertDialog = ({
  handleArchiveSavedSurvey,
  handleLoadSavedAnswers,
  open,
  setOpen,
}) => {
  const handleClose = () => {
    handleArchiveSavedSurvey();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Saved Answers'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            We found a previously saved survey. Would you like to restore those
            answers or start new?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleLoadSavedAnswers()} autoFocus>
            Restore
          </Button>
          <Button color='error' onClick={handleClose}>
            Start New
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertDialog;
