import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';

const UserProfileForm = ({
  formData,
  handleCreateUser,
  handleUpdateUser,
  stores,
  setFormData,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const requiredFields = getRequiredFields(formData);
    const isAllFieldsFilled = requiredFields.every(
      (fieldName) =>
        formData[fieldName] !== '' && formData[fieldName] !== undefined
    );
    setIsFormValid(isAllFieldsFilled);
  }, [formData]);

  const getRequiredFields = (formData) => {
    switch (formData.role) {
      case 'Store':
        return Object.keys(formData).filter(
          (fieldName) => fieldName !== 'department' && fieldName !== 'store'
        );
      case 'Basecamp':
        return Object.keys(formData).filter(
          (fieldName) =>
            fieldName !== 'store' &&
            fieldName !== 'defaultStore' &&
            fieldName !== 'storeName' &&
            fieldName !== 'selectedStore'
        );
      default:
        return Object.keys(formData).filter(
          (fieldName) =>
            fieldName !== 'department' &&
            fieldName !== 'store' &&
            fieldName !== 'defaultStore' &&
            fieldName !== 'storeName' &&
            fieldName !== 'selectedStore'
        );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'defaultStore') {
      const { storeName } = stores.find((s) => s.storeId === value);
      setFormData({
        ...formData,
        [name]: value,
        storeName,
        selectedStore: value,
      });
    } else if (name === 'role') {
      switch (value) {
        case 'Basecamp':
          setFormData({
            ...formData,
            [name]: value,
            defaultStore: '',
            store: '',
            storeName: '',
            selectedStore: '',
          });
          break;
        case 'DO':
          setFormData({
            ...formData,
            [name]: value,
            defaultStore: '',
            store: '',
            storeName: '',
            selectedStore: '',
            department: '',
          });
          break;
        default:
          setFormData({
            ...formData,
            [name]: value,
            department: '',
          });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleOnChange = (text) => {
    var cleaned = ('' + text).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '',
        number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
          ''
        );

      setFormData({
        ...formData,
        cell: number,
      });

      return;
    }
    setFormData({
      ...formData,
      cell: text,
    });
  };
  let forceStoreUser = true;
  if (
    (formData.role && formData.role === 'DO') ||
    (formData.role && formData.role === 'Basecamp')
  ) {
    forceStoreUser = false;
  }
  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled
            label='First Name'
            name='firstName'
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label='Last Name'
            name='lastName'
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Cell Phone'
            name='cell'
            value={formData.cell}
            onChange={(e) => handleOnChange(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Role*</InputLabel>
            <Select
              disabled={forceStoreUser}
              name='role'
              value={formData.role}
              onChange={handleChange}
              label='role'
              required
            >
              <MenuItem value='Store'>Store</MenuItem>
              <MenuItem value='Direct'>Direct</MenuItem>
              <MenuItem value='DO'>DO</MenuItem>
              <MenuItem value='Basecamp'>Basecamp</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {formData.role === 'Basecamp' && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Department*</InputLabel>
              <Select
                name='department'
                value={formData.department}
                onChange={handleChange}
                label='department'
                required
              >
                <MenuItem value='Accounting'>Accounting</MenuItem>
                <MenuItem value='Culinary'>Culinary</MenuItem>
                <MenuItem value='Development'>Development</MenuItem>
                <MenuItem value='Executive'>Executive</MenuItem>
                <MenuItem value='Facilities'>Facilities</MenuItem>
                <MenuItem value='Finance'>Finance</MenuItem>
                <MenuItem value='Guest Relations'>Guest Relations</MenuItem>
                <MenuItem value='IT'>IT</MenuItem>
                <MenuItem value='Marketing'>Marketing</MenuItem>
                <MenuItem value='NSO'>NSO</MenuItem>
                <MenuItem value='Operations Leadership'>
                  Operations Leadership
                </MenuItem>
                <MenuItem value='Operations Services'>
                  Operations Services
                </MenuItem>
                <MenuItem value='Payroll'>Payroll</MenuItem>
                <MenuItem value='Supply Chain'>Supply Chain</MenuItem>
                <MenuItem value='Talent'>Talent</MenuItem>
                <MenuItem value='Team Member Support'>
                  Team Member Support
                </MenuItem>
                <MenuItem value='Training'>Training</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {formData.role === 'Store' && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Store*</InputLabel>
              <Select
                disabled={stores?.length === 0}
                value={formData.selectedStore}
                onChange={handleChange}
                label='store*'
                name='defaultStore'
              >
                {stores?.map((store, i) =>
                  i === 0 ? (
                    <MenuItem key={store.storeId} value={store.storeId}>
                      {store.storeName}
                    </MenuItem>
                  ) : (
                    <MenuItem key={store.storeId} value={store.storeId}>
                      {store.storeName}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            disabled={!isFormValid}
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            style={{ margin: 4 }}
            onClick={formData.userId ? handleUpdateUser : handleCreateUser}
          >
            {formData.userId ? 'Update' : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserProfileForm;
