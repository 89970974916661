import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Backdrop,
  Container,
  CircularProgress,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { useUserAuth } from '../../context/UserAuthContext';
import { useSearch } from '../../context/SearchContext';

import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';
import MainContent from './MainContent/MainContent';
import MetricCard from './Metricard/Metricard';

import './OLOLookup.css';

const Main = () => {
  const navigate = useNavigate();

  const { user: firebaseUser, loading: authenticating } = useUserAuth();
  const {
    setStartDate,
    startDate,
    setEndDate,
    endDate,
    searchField,
    setSearchField,
    searchQuery,
    selectedOloStore,
    setSelectedOloStore,
    setSearchQuery,
  } = useSearch();
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [showAlert, setShowAlert] = useState('');
  const [selectedHandoffType, setSelectedHandoffType] = useState('all');
  const [orders, setOrders] = useState([]);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
  });
  const [metrics, setMetrics] = useState();
  const [lastUpdated, setLastUpdated] = useState(
    format(new Date(), 'M/d/yy hh:mm a')
  );

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetStores();
      handleGetUserInfo();
    }
  }, [firebaseUser]);

  useEffect(() => {
    if (firebaseUser.getIdToken && formData) {
      if (searchQuery.length > 0 || startDate || endDate) {
        handleSearch();
      } else if (selectedOloStore) {
        handleGetOrders(selectedOloStore);
        fetchMetrics(selectedOloStore);
      }
    }
  }, [selectedOloStore, firebaseUser, selectedHandoffType]);

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        navigate('/profile');
      } else {
        setFormData({
          ...response.data.userInfo,
        });
        if (response.data.userInfo.role.toLowerCase() === 'direct') {
          // if the direct user has a store, load their default store
          if (response.data.userInfo.selectedStore) {
            setSelectedOloStore(response.data.userInfo.selectedStore);
          } else {
            setSelectedOloStore('all');
          }
          // if the direct user does not have a store, load all stores
          // and let them see the button selector
        } else if (
          response.data.userInfo.role.toLowerCase() === 'store' &&
          response.data.userInfo.defaultStore
        ) {
          setSelectedOloStore(response.data.userInfo.defaultStore);
        } else if (
          response.data.userInfo.role.toLowerCase() === 'basecamp' &&
          !selectedOloStore
        ) {
          setSelectedOloStore('all');
        } else if (
          response.data.userInfo.role.toLowerCase() === 'do' &&
          !selectedOloStore
        ) {
          setSelectedOloStore('all');
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetStores = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/stores/${firebaseUser.email}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      const updatedStores = response.data.stores;
      updatedStores.unshift({ storeId: 'all', storeName: 'All' });
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      let goghUser;
      if (firebaseUser.email.toLowerCase().includes('gogh')) {
        goghUser = '&delivery=true';
      }
      let url = `/hd-api/olo/orders?user=${firebaseUser.email}&role=${
        formData.role
      }&searchField=${searchField}&storeId=${selectedOloStore}${
        goghUser ? goghUser : ''
      }${selectedHandoffType ? `&handoff=${selectedHandoffType}` : ''}`;

      if (searchField === 'timeWanted' || searchField === 'timePlaced') {
        if (startDate) {
          url += `&startDate=${startDate?.toISOString()}`;
        }
        if (endDate) {
          url += `&endDate=${endDate?.toISOString()}`;
        }
      } else {
        url += `&searchQuery=${searchQuery}`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      setOrders(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders from db:', error);
      setOrders([]);
      setLoading(false);
    }
  };

  const handleGetOrders = async (storeId) => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      let goghUser;
      if (firebaseUser.email.toLowerCase().includes('gogh')) {
        goghUser = '&delivery=true';
      }
      const response = await axios.get(
        `/hd-api/olo/orders?user=${firebaseUser.email}&role=${
          formData.role
        }&storeId=${storeId}${goghUser ? goghUser : ''}${
          selectedHandoffType ? `&handoff=${selectedHandoffType}` : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setOrders(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders from db:', error);
      setOrders([]);
      setLoading(false);
    }
  };

  const fetchMetrics = async (storeId) => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      const metricsResponse = await axios.get(
        `/hd-api/olo/metrics?user=${firebaseUser.email}&role=${formData.role}&storeId=${storeId}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        }
      );
      if (metricsResponse.data && metricsResponse.data.length > 0) {
        const metricsData = metricsResponse.data[0];
        setMetrics({
          sales: metricsData.TotalSales,
          orders: metricsData.TotalOrders,
          salesDiff: metricsData.SalesPercentageChange,
          ordersDiff: metricsData.OrdersPercentageChange,
          yesterdayTotalSales: metricsData.YesterdayTotalSales,
          yesterdayTotalOrders: metricsData.YesterdayTotalOrders,
        });
        setLastUpdated(format(new Date(), 'M/d/yy hh:mm a'));
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  const handleChangeSelectedStore = (e) => {
    setSelectedOloStore(e.target.value);
  };

  const handleClear = () => {
    setSearchQuery('');
    setStartDate(null);
    setEndDate(null);
    handleGetOrders(selectedOloStore);
    fetchMetrics(selectedOloStore);
  };

  const handleViewInvoice = (exportBatchId, invoiceNumber) => {
    navigate(`/olo-invoice/${exportBatchId}/${invoiceNumber}`);
  };

  const handleChangeSearchQuery = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleChangeSearchField = (e) => {
    const { value } = e.target;
    setSearchField(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    // Set up interval to call fetchMetrics and handleSearch every 5 minutes
    const interval = setInterval(() => {
      if (selectedOloStore) {
        fetchMetrics(selectedOloStore);
        handleSearch();
      }
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [selectedOloStore, handleSearch, fetchMetrics]);

  const { role } = formData;
  let showStoreFilter = false;
  if (
    role === 'DO' ||
    role === 'Basecamp' ||
    (role === 'Direct' && !formData.selectedStore)
  ) {
    showStoreFilter = true;
  }
  let searchInput;
  if (searchField === 'timeWanted' || searchField === 'timePlaced') {
    searchInput = (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText='Start Date'
          customInput={
            <TextField
              size='small'
              style={{ backgroundColor: 'white', marginTop: 8 }}
            />
          }
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText='End Date'
          customInput={
            <TextField
              size='small'
              style={{ backgroundColor: 'white', marginTop: 8 }}
            />
          }
        />
      </div>
    );
  } else {
    searchInput = (
      <TextField
        sx={{ backgroundColor: 'white', marginTop: 1 }}
        size='small'
        value={searchQuery}
        name='searchQuery'
        onChange={handleChangeSearchQuery}
        onKeyPress={handleKeyPress}
      />
    );
  }

  let content;
  if (authenticating) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Typography style={{ fontFamily: 'Lato', fontSize: 34 }}>
          Olo Catering Orders
        </Typography>
        <Grid
          container
          spacing={1}
          justifyContent='center'
          sx={{ marginTop: 0 }}
        >
          {metrics && (
            <Grid item xs={12} sm={6} md={4}>
              <MetricCard lastUpdated={lastUpdated} metrics={metrics} />
            </Grid>
          )}
        </Grid>
        <MainContent
          firebaseUser={firebaseUser}
          searchField={searchField}
          handleChangeSearchField={handleChangeSearchField}
          searchInput={searchInput}
          handleSearch={handleSearch}
          handleClear={handleClear}
          handleChangeSelectedStore={handleChangeSelectedStore}
          handleViewInvoice={handleViewInvoice}
          showStoreFilter={showStoreFilter}
          orders={orders}
          setLoading={setLoading}
          selectedOloStore={selectedOloStore}
          setSelectedHandoffType={setSelectedHandoffType}
          selectedHandoffType={selectedHandoffType}
          stores={stores}
        />
      </div>
    );
  }

  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='xl'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default Main;
