import React, { useState } from 'react';
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function ScrollDialog({
  open,
  handleClose,
  preferredCompetitors,
  setPreferredCompetitors,
}) {
  const initialRestaurants = [
    { id: 1, name: `McDonald's` },
    { id: 2, name: `Starbucks` },
    { id: 3, name: `Chick-fil-A` },
    { id: 4, name: `Taco Bell` },
    { id: 5, name: `Wendy’s` },
    { id: 6, name: `Dunkin’` },
    { id: 7, name: `Burger King` },
    { id: 8, name: `Subway` },
    { id: 9, name: `Chipotle` },
    { id: 10, name: `Domino’s` },
  ];
  const [restaurants, setRestaurants] = useState(initialRestaurants);
  const [newRestaurantName, setNewRestaurantName] = useState('');

  const handleAddRestaurant = () => {
    if (newRestaurantName.trim() !== '') {
      const newRestaurant = {
        id: restaurants.length + 1,
        name: newRestaurantName.trim(),
      };
      setRestaurants([...restaurants, newRestaurant]);
      setPreferredCompetitors([...preferredCompetitors, newRestaurant]);
      setNewRestaurantName('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddRestaurant();
    }
  };

  const toggleRestaurant = (restaurant) => {
    if (preferredCompetitors.includes(restaurant)) {
      setPreferredCompetitors(
        preferredCompetitors.filter((res) => res !== restaurant)
      );
    } else {
      setPreferredCompetitors([...preferredCompetitors, restaurant]);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          Preferred Competitors
        </DialogTitle>
        <DialogContent dividers={'paper'}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label='Add Restaurant'
                variant='outlined'
                value={newRestaurantName}
                onChange={(e) => setNewRestaurantName(e.target.value)}
                onKeyPress={handleKeyPress}
                fullWidth
                style={{ marginBottom: 16 }}
              />
              <Button
                variant='contained'
                color='primary'
                onClick={handleAddRestaurant}
                style={{ marginBottom: 16 }}
              >
                Add Restaurant
              </Button>
              <Divider style={{ marginBottom: 8 }} />
            </Grid>
            <Grid item xs={6}>
              {restaurants.length === 0 ? (
                <Typography variant='body1'>No restaurants found.</Typography>
              ) : (
                <>
                  <Typography variant='h6' gutterBottom>
                    Available Restaurants
                  </Typography>
                  <List>
                    {restaurants
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((restaurant) => (
                        <ListItem
                          key={restaurant.id}
                          button
                          onClick={() => toggleRestaurant(restaurant)}
                        >
                          <Checkbox
                            checked={preferredCompetitors.includes(restaurant)}
                            onChange={() => toggleRestaurant(restaurant)}
                          />
                          <ListItemText primary={restaurant.name} />
                        </ListItem>
                      ))}
                  </List>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              {preferredCompetitors.length > 0 && (
                <>
                  <Typography variant='h6' gutterBottom>
                    Selected Restaurants
                  </Typography>
                  <List>
                    {preferredCompetitors.map((restaurant) => (
                      <ListItem key={restaurant.id}>
                        <ListItemText primary={restaurant.name} />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
