import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, CircularProgress, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

import { useUserAuth } from '../../context/UserAuthContext';
import { CategoryContext } from '../../context/CategoryContext';

import SupportCategories from '../../components/SupportCategories/SupportCategories';
import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';
import PageTitle from '../../components/PageTitle/PageTitle';

import './Home.css';

const Main = () => {
  const navigate = useNavigate();
  const { updateSubcategories } = useContext(CategoryContext);
  const {
    user: firebaseUser,
    loading: authenticating,
    selectedStore,
    setSelectedStore,
  } = useUserAuth();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
    selectedStore,
  });
  const [gettingCategories, setGettingCategories] = useState(false);
  const [stores, setStores] = useState([]);
  const [showAlert, setShowAlert] = useState('');
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      initialCalls();
    }
  }, [firebaseUser]);

  const initialCalls = async () => {
    await handleGetUserInfo();
    handleGetStores();
    handleGetMessage();
    getCategories();
  };

  const getCategories = async () => {
    try {
      setGettingCategories(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/categories/user=${firebaseUser.email}/list`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setCategories(
        response.data.categories.sort(
          (a, b) => a.fields.DisplayOrder - b.fields.DisplayOrder
        )
      );
      updateSubcategories(
        response.data.subCategories.sort(
          (a, b) => a.fields.DisplayOrder - b.fields.DisplayOrder
        )
      );
      setGettingCategories(false);
    } catch (error) {
      console.error('Error fetching category info from db:', error);
      setGettingCategories(false);
    }
  };

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        navigate('/profile');
      } else {
        setFormData({
          ...response.data.userInfo,
          defaultStore: selectedStore,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetMessage = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/messages/global`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data.message) {
        setShowAlert(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetStores = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/stores/${firebaseUser.email}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const s = stores.find((s) => s.storeId === value);
    setSelectedStore(s.storeId);
    setFormData({
      ...formData,
      [name]: value,
      storeName: s.storeName,
    });
  };

  let content;
  if (authenticating || loading || gettingCategories) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div>
        <PageTitle
          formData={formData}
          handleChange={handleChange}
          stores={stores}
        />
        <SupportCategories
          categories={categories}
          firebaseUser={firebaseUser}
        />
      </div>
    );
  }
  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='md'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
    </div>
  );
};

export default Main;
