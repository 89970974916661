import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Button,
  Paper,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
  form: {
    width: '100%',
    marginTop: 4,
  },
  submit: {
    margin: 4,
  },
  googleButton: {
    margin: 8,
  },
};

const RestaurantForm = ({
  formData,
  stores,
  defaultStore,
  setFormData,
  handleSetDefaultStore,
}) => {
  const navigate = useNavigate();

  const handleChange = (e) => {
    const storeId = e.target.value;
    const storeName = stores.find((s) => s.storeId === storeId);
    setFormData({
      ...formData,
      storeName: storeName.storeName,
      defaultStore: e.target.value,
    });
  };
  return (
    <Paper elevation={3} id='default-store-form-container'>
      <Grid container spacing={1}>
        <Grid justifyContent='center' container item xs={2} sm={2}></Grid>
        <Grid justifyContent='center' container item xs={8} sm={8}>
          <Typography style={{ fontFamily: 'Lato' }} variant='h5'>
            Please select your default store.
          </Typography>
        </Grid>
        <Grid
          style={{ paddingTop: 0 }}
          justifyContent='flex-end'
          container
          item
          xs={2}
          sm={2}
        >
          <IconButton onClick={() => navigate(-1)} aria-label='delete'>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <div style={{ width: '100%', maxWidth: 400, margin: 16 }}>
        <FormControl fullWidth>
          <InputLabel>Store</InputLabel>
          <Select
            disabled={stores?.length === 0}
            value={defaultStore}
            onChange={handleChange}
            label='store'
          >
            {stores?.map((store, i) =>
              i === 0 ? (
                <MenuItem key={store.storeId} value={store.storeId}>
                  {store.storeName}
                </MenuItem>
              ) : (
                <MenuItem key={store.storeId} value={store.storeId}>
                  {store.storeName}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </div>
      <Button
        type='submit'
        variant='contained'
        color='primary'
        style={styles.submit}
        onClick={handleSetDefaultStore}
      >
        Confirm
      </Button>
    </Paper>
  );
};

export default RestaurantForm;
