import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

const types = [
  'american_restaurant',
  'bakery',
  'bar',
  'barbecue_restaurant',
  'brazilian_restaurant',
  'breakfast_restaurant',
  'brunch_restaurant',
  'cafe',
  'chinese_restaurant',
  'coffee_shop',
  'fast_food_restaurant',
  'french_restaurant',
  'greek_restaurant',
  'hamburger_restaurant',
  'ice_cream_shop',
  'indian_restaurant',
  'indonesian_restaurant',
  'italian_restaurant',
  'japanese_restaurant',
  'korean_restaurant',
  'lebanese_restaurant',
  'meal_delivery',
  'meal_takeaway',
  'mediterranean_restaurant',
  'mexican_restaurant',
  'middle_eastern_restaurant',
  'pizza_restaurant',
  'ramen_restaurant',
  'restaurant',
  'sandwich_shop',
  'seafood_restaurant',
  'spanish_restaurant',
  'steak_house',
  'sushi_restaurant',
  'thai_restaurant',
  'turkish_restaurant',
  'vegan_restaurant',
  'vegetarian_restaurant',
  'vietnamese_restaurant',
];

const ExcludedTypeSelectionDialog = ({
  open,
  onClose,
  selectedTypes,
  onTypeToggle,
  onConfirm,
  searchType,
}) => {
  let disableCheckboxes, selectedType;
  if (searchType === 'specific' && selectedTypes.length > 0) {
    disableCheckboxes = true;
    selectedType = selectedTypes[0];
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Select Excluded Types{' '}
        {searchType === 'specific' ? `(limited to 1)` : ''}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          {types.map((type) => (
            <FormControlLabel
              key={type}
              disabled={disableCheckboxes && type !== selectedType}
              control={
                <Checkbox
                  checked={selectedTypes.includes(type)}
                  onChange={() => onTypeToggle(type)}
                />
              }
              label={type.replace('_', ' ')}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExcludedTypeSelectionDialog;
