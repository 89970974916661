import React from 'react';
import { Container, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HomeIcon from '@mui/icons-material/Home';

const Header = ({ toggleDrawer, isTabletOrMobile, disable }) => {
  const navigate = useNavigate();

  return (
    <Container maxWidth='xl'>
      <Grid container item xs={12}>
        <Grid
          xs={12}
          container
          item
          justifyContent={'flex-end'}
          alignContent={'center'}
        >
          <Grid
            justifyContent={'flex-end'}
            alignContent={'center'}
            container
            item
            style={{ marginTop: 8 }}
            spacing={1}
          >
            <Grid item>
              <Button
                variant='contained'
                // color='info'
                style={{ fontFamily: 'Lato' }}
                onClick={() => navigate('/home')}
                size='small'
              >
                {isTabletOrMobile ? <HomeIcon /> : 'Home'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={disable}
                type='submit'
                variant='contained'
                color='info'
                style={{ fontFamily: 'Lato' }}
                onClick={() => navigate('/profile')}
                size='small'
              >
                {isTabletOrMobile ? <ManageAccountsIcon /> : 'Edit Profile'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
