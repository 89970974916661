import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

import StoreSelector from '../StoreSelector/StoreSelector';

const PageTitle = ({ handleSaveSurvey, formData, handleChange, stores }) => {
  const navigate = useNavigate();
  const { role, firstName, lastName, storeName } = formData;

  const handleChangeStore = () => navigate('/change-store');
  let showSelector = false;
  if (role === 'DO') {
    showSelector = true;
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography
        style={{
          marginTop: 8,
          marginBottom: 0,
          textAlign: 'center',
          fontFamily: 'Lato',
        }}
        variant='h4'
      >
        {firstName} {lastName}
      </Typography>
      {role === 'Store' && (
        <React.Fragment>
          <Typography
            style={{
              marginTop: 8,
              marginBottom: 16,
              textAlign: 'center',
              fontFamily: 'Lato',
            }}
            variant='h5'
          >
            {storeName}
          </Typography>

          <Button
            variant='contained'
            size='small'
            style={{ fontFamily: 'Lato' }}
            onClick={handleChangeStore}
          >
            Change Store
          </Button>
        </React.Fragment>
      )}
      {showSelector && (
        <StoreSelector
          stores={stores}
          formData={formData}
          handleChange={handleChange}
          handleSaveSurvey={handleSaveSurvey}
        />
      )}
    </div>
  );
};

export default PageTitle;
