import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import {
  Backdrop,
  Box,
  Container,
  CircularProgress,
  Paper,
  Divider,
  Card,
  Grid,
  Button,
  Tabs,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { useMediaQuery } from 'react-responsive';

import { useUserAuth } from '../../context/UserAuthContext';

import AlertDialog from './Dialog';
import FinishedDialog from './FinishedDialog';
import NotFinishedDialog from './NotFinishedDialog';
import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';
import Section from '../../components/Section/Section';
import PageTitle from '../../components/PageTitle/PageTitle';

import './FacilitiesWalkthrough.css';

const FacilitiesWalkthrough = () => {
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [newSurvey, setNewSurvey] = useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const [surveyId, setSurveyId] = useState();
  const navigate = useNavigate();
  const { user: firebaseUser, loading: authenticating } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [loadingAnswers, setLoadingAnswers] = useState(false);
  const [showAlert, setShowAlert] = useState('');
  const [showFinished, setShowFinished] = useState(false);
  const [saveType, setSaveType] = useState();
  const [surveyInfo, setSurveyInfo] = useState();
  const [open, setOpen] = useState(false);
  const [notFinished, setNotFinished] = useState(false);
  const [savedAnswers, setSavedAnswers] = useState();
  const [savedImages, setSavedImages] = useState();
  const [stores, setStores] = useState([]);
  const [frIDs, setFrIDs] = useState({});
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
  });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetUserInfo();
      handleGetMessage();
      handleGetSurveyInfo();
      handleGetStores();
    }
  }, [firebaseUser]);

  useEffect(() => {
    if (!surveyId) {
      setSurveyId(uuidv4());
    }
  }, []);

  useEffect(() => {
    if (formData.defaultStore) {
      setNewSurvey(true);
      setSavedAnswers();
      setSurveyId(uuidv4());
      reset(undefined, { keepDefaultValues: true });
      setTabValue(0);
      handleGetSavedSurvey(1);
    }
  }, [formData.defaultStore]);

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        navigate('/profile');
      } else {
        setFormData(response.data.userInfo);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetMessage = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/messages/global`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data.message) {
        setShowAlert(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetSurveyInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/facilities-walkthrough/info`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data) {
        setSurveyInfo(response.data);
        if (formData.defaultStore) {
          handleGetSavedSurvey(tabValue + 1);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(
        'Error fetching facilities walkthrough info from db:',
        error
      );
      setLoading(false);
    }
  };

  const handleGetSavedSurvey = async (section) => {
    try {
      setLoadingAnswers(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/facilities-walkthrough/user=${firebaseUser.email}/store=${formData.defaultStore}/section=${section}/answers/saved`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      await handleGetSavedImages();
      if (response.data && response.data.length > 0) {
        setSavedAnswers(response.data);
        if (newSurvey) {
          setOpen(true);
        } else {
          reset(undefined, { keepDefaultValues: true });
          handleLoadSavedAnswers(response.data);
        }
      }
      setLoadingAnswers(false);
    } catch (error) {
      console.error('Error fetching saved survey from db:', error);
      setLoadingAnswers(false);
    }
  };

  const handleGetSavedImages = async () => {
    try {
      setLoadingAnswers(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/facilities-walkthrough/user=${firebaseUser.email}/store=${formData.defaultStore}/images/saved`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data && response.data.length > 0) {
        setSavedImages(response.data);
      }
      setLoadingAnswers(false);
    } catch (error) {
      console.error('Error fetching saved survey from db:', error);
      setLoadingAnswers(false);
    }
  };

  const handleArchiveSavedSurvey = async () => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      await axios.delete(
        `/hd-api/facilities-walkthrough/user=${firebaseUser.email}/store=${formData.defaultStore}/saved`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
    } catch (error) {
      console.error('Error fetching saved survey from db:', error);
    }
  };

  const handleLoadSavedAnswers = (answers) => {
    const updatedFrIDs = {};
    const updatedValues = {};
    let sId;
    if (answers) {
      answers.forEach(({ formName, issue, owner, value, frID, fwtId }) => {
        sId = fwtId;
        const [section, area, id] = formName.split('-');
        if (section === 'strengths') {
          updatedValues.strengths = value;
        }
        if (section === 'opportunities') {
          updatedValues.opportunities = value;
        }
        if (issue) {
          updatedValues[`${section}-${area}-explain`] = issue;
        }
        if (owner) {
          updatedValues[`${section}-${area}-responsible`] = owner;
        }
        updatedValues[formName] = String(value);
        updatedFrIDs[formName] = frID;
      });
    } else {
      savedAnswers.forEach(({ formName, issue, owner, value, frID, fwtId }) => {
        sId = fwtId;
        const [section, area, id] = formName.split('-');
        if (section === 'strengths') {
          updatedValues.strengths = value;
        }
        if (section === 'opportunities') {
          updatedValues.opportunities = value;
        }
        if (issue) {
          updatedValues[`${section}-${area}-explain`] = issue;
        }
        if (owner) {
          updatedValues[`${section}-${area}-responsible`] = owner;
        }
        updatedValues[formName] = String(value);
        updatedFrIDs[formName] = frID;
      });
    }

    const updatedFiles = {};
    if (savedImages) {
      savedImages.forEach(({ fileName, image }) => {
        const [section, area, id] = fileName.split('-');
        const key = `${section}-${area}`;

        if (!updatedFiles[key]) {
          updatedFiles[key] = [];
        }

        const byteArray = new Uint8Array();
        const blob = new Blob([byteArray], { type: 'image/jpeg' });

        const file = new File([blob], `${section}-${area}-${id}`, {
          type: 'image/jpeg',
        });

        updatedFiles[key].push(file);
      });
      Object.keys(updatedFiles).forEach((key) => {
        const fileArray = updatedFiles[key];
        const [section, area] = key.split('-');
        updatedValues[`${section}-${area}-files`] = fileArray;
      });
    }
    reset(updatedValues, { keepDefaultValues: true });

    // setNewSurvey(false);
    setFrIDs(updatedFrIDs);
    setSurveyId(sId);
    setOpen(false);
  };

  const onSubmit = async (data, e) => {
    const { id } = e.nativeEvent.submitter;
    // if (id === 'save') handleSaveSurvey(data);
    if (id === 'submit') {
      const complete = await checkSurveyComplete();
      if (complete) {
        await handleSubmitSurvey(data);
      } else {
        setSaving(false);
        // show dialog that not all questions have been filled out
        setNotFinished(true);
      }
    }
  };

  const checkSurveyComplete = async () => {
    try {
      setSaving(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/facilities-walkthrough/complete/survey=${surveyId}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error checking if survey is complete from db:', error);
      setSaving(false);
    }
  };

  const handleSubmitSurvey = async (data) => {
    const storeId = formData.defaultStore;
    const filteredData = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => ({ question: d, value: data[d] }));

    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      // Make the API call with axios
      await axios.post(
        `/hd-api/facilities-walkthrough/submit`,
        { fwtId: surveyId, storeId, data: filteredData },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSaving(false);
      setSaveType('submitted');
      setShowFinished(true);
    } catch (error) {
      console.error('Error submitting facilities walkthrough info:', error);
      setSaving(false);
    }
  };

  const handleSaveSurvey = async (e) => {
    e.preventDefault();
    const data = getValues();
    const storeId = formData.defaultStore;
    const filteredData = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => ({ question: d, value: data[d] }));
    if (filteredData.length > 0) {
      try {
        setSaving(true);
        const firebaseUserIdToken = await firebaseUser.getIdToken(true);

        // Create a new FormData object
        const formData = new FormData();

        // Append form data
        filteredData.forEach((item, index) => {
          formData.append(`data[${index}][question]`, item.question);
          formData.append(`data[${index}][value]`, item.value);
          if (frIDs[item.question]) {
            formData.append(`data[${index}][frID]`, frIDs[item.question]);
          }
          // Append files if they exist
          if (item.question.indexOf('files') > -1) {
            item.value.forEach((file, fileIndex) => {
              if (savedImages) {
                const fileExists = savedImages.find(
                  ({ fileName }) => fileName === file.name
                );
                if (!fileExists) {
                  formData.append(`files`, file);
                }
              } else {
                formData.append(`files`, file);
              }
            });
          }
        });

        formData.append(`data[${filteredData.length}][storeId]`, storeId);
        formData.append(
          `data[${filteredData.length + 1}][user]`,
          firebaseUser.email
        );
        formData.append(`data[${filteredData.length + 2}][surveyId]`, surveyId);
        // Make the API call with axios
        await axios.post(`/hd-api/facilities-walkthrough/save`, formData, {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
            'Content-Type': 'multipart/form-data', // Important to set the content type
          },
        });
        reset(undefined, { keepDefaultValues: true });
        setSavedAnswers();
        // setNewSurvey(false);
        setSaving(false);
        // setTabValue(tabValue + 1);
        // setShowFinished(true);
      } catch (error) {
        console.error('Error saving facilities walkthrough info:', error);
        setSaving(false);
      }
    }
  };

  const handleGetStores = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/stores/${firebaseUser.email}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const s = stores.find((s) => s.storeId === value);
    setFormData({
      ...formData,
      [name]: value,
      storeName: s.storeName,
    });
  };

  const handleChangeTab = async (event, newValue) => {
    await handleSaveSurvey(event);
    setTabValue(newValue);
    if (!newSurvey) {
      handleGetSavedSurvey(newValue + 1);
    }
  };

  let content, summary, buttons, tabs;
  if (authenticating || loading) {
    tabs = (
      <Grid
        container
        justifyContent={'center'}
        alignContent={'center'}
        style={{ minHeight: 200, backgroundColor: 'transparent' }}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  } else if (saving) {
    tabs = (
      <Grid
        container
        justifyContent={'center'}
        alignContent={'center'}
        style={{ minHeight: 200, backgroundColor: 'transparent' }}
      >
        <Grid item style={{ textAlign: 'center' }}>
          <Typography style={{ marginBottom: 8, fontFamily: 'Lato' }}>
            Please wait while we save your answers...
          </Typography>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  } else if (surveyInfo && formData.defaultStore) {
    summary = (
      <CustomTabPanel
        index={Object.keys(surveyInfo).length}
        style={{ padding: 8, backgroundColor: 'rgba(195,202,216,.3)' }}
        value={tabValue}
      >
        <Card style={{ height: '100%', padding: 16 }}>
          <Typography style={{ fontFamily: 'Lato', fontSize: 16 }}>
            Strengths
          </Typography>
          <Controller
            rules={{
              required: {
                value: true,
                message: 'An answer is required for this field',
              },
            }}
            name='strengths'
            control={control}
            render={({ field }) => (
              <TextField
                error={errors.strengths ? true : false}
                helperText={String(errors.strengths?.message ?? '')}
                id='outlined-textarea'
                multiline
                minRows={8}
                style={{ width: '100%', marginTop: 16 }}
                {...field}
              />
            )}
          />
          <Typography
            style={{
              fontFamily: 'Lato',
              fontSize: 16,
              marginTop: 16,
            }}
          >
            Opportunities
          </Typography>
          <Controller
            rules={{
              required: {
                value: true,
                message: 'An answer is required for this field',
              },
            }}
            name='opportunities'
            control={control}
            render={({ field }) => (
              <TextField
                error={errors.opportunities ? true : false}
                helperText={String(errors.opportunities?.message ?? '')}
                id='outlined-textarea'
                multiline
                minRows={8}
                style={{ width: '100%', marginTop: 16 }}
                {...field}
              />
            )}
          />
        </Card>
      </CustomTabPanel>
    );
    content = Object.keys(surveyInfo).map((section, i) => {
      return (
        <CustomTabPanel
          key={section}
          style={{ padding: 8, backgroundColor: 'rgba(195,202,216,.3)' }}
          value={tabValue}
          index={i}
        >
          <Section
            control={control}
            errors={errors}
            info={surveyInfo[section]}
            section={section}
            i={i}
            setValue={setValue}
            watch={watch}
          />
        </CustomTabPanel>
      );
    });
    tabs = (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label='basic tabs example'
            variant='scrollable'
            scrollButtons='auto'
          >
            {Object.keys(surveyInfo).map((section, i) => (
              <Tab label={section} {...a11yProps(i)} />
            ))}
            <Tab
              label='Summary'
              {...a11yProps(Object.keys(surveyInfo).length)}
            />
          </Tabs>
        </Box>
        {content}
        {summary}
      </Box>
    );
    buttons = (
      <div style={{ marginBottom: 16 }}>
        <Divider style={{ margin: '12px 8px' }} />
        <Grid container justifyContent='space-between' spacing={2}>
          <Grid item>
            <Button
              onClick={(e) => handleChangeTab(e, tabValue - 1)}
              size='large'
              variant='contained'
              color='inherit'
              // type='submit'
              id='save'
              style={{ marginLeft: 16 }}
              disabled={tabValue === 0}
            >
              Back
            </Button>
          </Grid>
          {tabValue === Object.keys(surveyInfo).length ? (
            <Grid item>
              <Button
                size='large'
                variant='contained'
                color='primary'
                type='submit'
                id='submit'
                style={{ marginRight: 16 }}
              >
                Submit
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Button
                onClick={(e) => handleChangeTab(e, tabValue + 1)}
                size='large'
                variant='contained'
                color='primary'
                // type='submit'
                id='save'
                style={{ marginRight: 16 }}
              >
                Next
              </Button>
            </Grid>
          )}

          {/* <Grid item>
            <Button
              size='large'
              style={{ minWidth: 150 }}
              variant='contained'
              type='submit'
              id='submit'
            >
              Submit
            </Button>
          </Grid> */}
        </Grid>
      </div>
    );
  } else if (!formData.defaultStore) {
    tabs = (
      <Grid
        container
        justifyContent={'center'}
        alignContent={'center'}
        style={{ minHeight: 200 }}
      >
        <Grid item>
          <Typography>Please select a store to begin.</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='md' style={{ padding: 12 }}>
        <div style={{ padding: '0 16px' }}>
          <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        </div>
        <div id='main'></div>
        <PageTitle
          formData={formData}
          handleChange={handleChange}
          stores={stores}
          handleSaveSurvey={handleSaveSurvey}
        />
        <Paper style={{ paddingBottom: 4, margin: '24px 0' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {tabs}
            {/* {summary} */}
            {buttons}
          </form>
        </Paper>
      </Container>
      <NotFinishedDialog open={notFinished} setOpen={setNotFinished} />
      <AlertDialog
        handleArchiveSavedSurvey={handleArchiveSavedSurvey}
        handleLoadSavedAnswers={handleLoadSavedAnswers}
        open={open}
        setOpen={setOpen}
      />
      <FinishedDialog open={showFinished} type={saveType} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingAnswers}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: 0 }}>
          <Typography style={{ fontFamily: 'Lato' }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default FacilitiesWalkthrough;
