import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';

import { auth } from '../firebase-config';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [selectedStore, setSelectedStore] = useState();
  const [loading, setLoading] = useState(true);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function googleSignIn() {
    setLoading(true);
    const googleAuthProvider = new GoogleAuthProvider();

    return new Promise((resolve, reject) => {
      signInWithPopup(auth, googleAuthProvider)
        .then(async (result) => {
          const provider = result.user.providerData;
          if (provider?.length > 0) {
            const accepted = await authenticateEmail(provider[0].uid);
            if (accepted) {
              resolve(result);
            } else {
              logOut();
              reject(new Error('Invalid user.'));
            }
          } else {
            logOut();

            reject(new Error('Invalid user.'));
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const authenticateEmail = async (email) => {
    try {
      const response = await axios.get(`/hd-api/user/check/${email}`, {
        'Content-Type': 'application/json',
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching users from db:', error);
      return false;
    }
  };
  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        googleSignIn,
        loading,
        selectedStore,
        setSelectedStore,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
