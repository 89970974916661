import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, CircularProgress } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

import SupportSubCategories from '../../components/SupportSubCategories/SupportSubCategories';
import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';
import PageTitle from '../../components/PageTitle/PageTitle';

import { useUserAuth } from '../../context/UserAuthContext';
import { CategoryContext } from '../../context/CategoryContext';

import './Subcategory.css';

const Subcategory = (props) => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const { categoryInfo, subcategories, updateSubcategories, updateCategory } =
    useContext(CategoryContext);
  const { category, subCategories, subDepartmentHeadline } = categoryInfo;
  const {
    user: firebaseUser,
    loading: authenticating,
    selectedStore,
    setSelectedStore,
  } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
    selectedStore,
  });
  const [gettingSubCategories, setGettingSubCategories] = useState(false);
  const [stores, setStores] = useState([]);
  const [showAlert, setShowAlert] = useState('');
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetUserInfo();
      handleGetMessage();
      handleGetSubCategories();
      handleGetStores();
    }
  }, [firebaseUser]);

  const handleGetStores = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/stores/${firebaseUser.email}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };

  const handleGetSubCategories = async () => {
    try {
      setGettingSubCategories(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/categories/${categoryId}/user=${firebaseUser.email}/list`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      updateSubcategories(
        response.data.subCategories.sort(
          (a, b) => a.fields.DisplayOrder - b.fields.DisplayOrder
        )
      );
      if (response.data.category) {
        const categoryInfo = response.data.category[0].fields;
        updateCategory({
          category: categoryInfo.Name,
          id: categoryId,
          subCategories: categoryInfo.subDepartments,
          subDepartmentHeadline: categoryInfo.SubDepartmentHeadline,
        });
      }

      setGettingSubCategories(false);
    } catch (error) {
      console.error('Error fetching subcategory info from db:', error);
      setGettingSubCategories(false);
    }
  };

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        navigate('/profile');
      } else {
        setFormData({
          ...response.data.userInfo,
          defaultStore: selectedStore,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetMessage = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/hd-api/messages/global`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data.message) {
        setShowAlert(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const s = stores.find((s) => s.storeId === value);
    setSelectedStore(s.storeId);
    setFormData({
      ...formData,
      [name]: value,
      storeName: s.storeName,
    });
  };

  let content;

  if (authenticating || loading || gettingSubCategories) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div>
        <PageTitle
          formData={formData}
          handleChange={handleChange}
          stores={stores}
        />
        <SupportSubCategories
          subCategory={subCategories}
          firebaseUser={firebaseUser}
          subCategories={subcategories}
          category={category}
          subDepartmentHeadline={subDepartmentHeadline}
          categoryId={categoryId}
        />
      </div>
    );
  }
  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='md'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
    </div>
  );
};

export default Subcategory;
