import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';

const EmailDialog = ({
  storeName,
  storeId,
  emailAddress,
  invoiceNumber,
  confirmSend,
  open,
  onClose,
}) => {
  const [to, setTo] = useState(emailAddress);
  const [replyTo, setReplyTo] = useState(
    `catering${storeId}@mendocinofarms.com`
  );
  const [subject, setSubject] = useState(
    `Your Mendocino Farms ${storeName} Inv #${invoiceNumber}`
  );
  const [body, setBody] = useState(
    `Thank you for your recent Mendocino Farms Catering order.  Your final invoice is attached. If you have any questions please contact your local Mendocino Farms location directly - their contact information is located at the bottom of the attached confirmation. Thank you so much for choosing Mendocino Farms!  We look forward to working with you again soon.`
  );
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setStatus('');

    try {
      await confirmSend(to, replyTo, subject, body);
      setStatus('Email sent successfully!');
    } catch (error) {
      setStatus(`Error sending email: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Send Email</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin='dense'
            label='To'
            type='email'
            fullWidth
            variant='outlined'
            value={to}
            onChange={(e) => setTo(e.target.value)}
            required
          />
          <TextField
            autoFocus
            margin='dense'
            label='Reply To'
            type='replyTo'
            fullWidth
            variant='outlined'
            value={replyTo}
            onChange={(e) => setReplyTo(e.target.value)}
            required
          />
          <TextField
            margin='dense'
            label='Subject'
            type='text'
            fullWidth
            variant='outlined'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
          <TextField
            margin='dense'
            label='Body'
            type='text'
            fullWidth
            variant='outlined'
            multiline
            rows={4}
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
          />
          <DialogActions>
            <Button onClick={onClose} color='error'>
              Cancel
            </Button>
            {status === 'Email sent successfully!' ? (
              <Button color='primary' onClick={onClose}>
                Close
              </Button>
            ) : (
              <Button type='submit' color='primary' disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Send Email'}
              </Button>
            )}
          </DialogActions>
        </form>
        {status && <p>{status}</p>}
      </DialogContent>
    </Dialog>
  );
};

export default EmailDialog;
