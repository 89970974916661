import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CategoryContext } from '../../context/CategoryContext';

const buttonColors = [
  { label: 'Support', color: 'info' },
  { label: 'Draw Attention', color: 'error' },
  { label: 'Function', color: 'warning' },
];

const SupportSubCategories = ({
  firebaseUser,
  subCategories,
  subCategory,
  subDepartmentHeadline,
  category,
  categoryId,
}) => {
  const navigate = useNavigate();
  const [iframeUrl, setIframeUrl] = useState(null);
  const { updateCategory } = useContext(CategoryContext);

  const handleButtonClick = async (
    name,
    jotformId,
    url,
    subcategoryId,
    subcategory
  ) => {
    updateCategory({
      category,
      jotformId,
      url,
      subcategory,
    });
    if (name === 'Catering Invoices') {
      navigate('/olo-lookup');
      return;
    }
    if (jotformId) {
      navigate(
        `/category/${categoryId}/subcategory/${subcategoryId}/form/${jotformId}`
      );
    }
    if (url?.startsWith('http://') || url?.startsWith('https://')) {
      window.location.href = url;
    } else if (url) {
      try {
        const firebaseUserIdToken = await firebaseUser.getIdToken(true);
        const response = await axios.get(`/hd-api/public/${url}`, {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        });
        // Set the HTML content in the iframe
        setIframeUrl(`data:text/html;base64,${btoa(response.data)}`);
      } catch (error) {
        console.error('Error fetching HTML file:', error);
      }
    }
  };

  const buttons = subCategories
    .filter(({ id }) => subCategory.indexOf(id) > -1)
    .map(({ fields, id }, index) => {
      const { Name, JotFormID, ...rest } = fields;
      const bcText = rest['Button Color'];
      const url = rest['Web Page Destination'];
      const color = buttonColors.find((b) => b.label === bcText);
      return (
        <Grid item key={index}>
          <Button
            size='large'
            variant='contained'
            color={color ? color.color : 'info'}
            style={{
              fontFamily: 'Lato',
              width: 240,
              height: '100%',
            }}
            onClick={() => handleButtonClick(Name, JotFormID, url, id, Name)}
          >
            {Name}
          </Button>
        </Grid>
      );
    });
  return (
    <div style={{ width: '100%', marginTop: 8 }}>
      <Grid
        container
        style={{ margin: 'auto' }}
        justifyContent='center'
        xs={12}
      >
        {iframeUrl ? (
          // Render iframe when the iframeUrl is set
          <iframe
            src={iframeUrl}
            title='Embedded Content'
            width='100%'
            height='500px'
            style={{ border: 'none' }}
          />
        ) : (
          // Render the original content with buttons
          <Paper style={{ margin: 16, padding: 24 }}>
            <Typography
              style={{
                marginBottom: 12,
                textAlign: 'center',
                fontFamily: 'Lato',
              }}
              variant='h5'
            >
              {category}
            </Typography>
            <Typography
              style={{
                marginBottom: 24,
                textAlign: 'center',
                fontFamily: 'Lato',
              }}
              variant='subtitle1'
            >
              {subDepartmentHeadline}
            </Typography>
            <Grid xs={12} item container justifyContent='center' spacing={2}>
              {buttons}
            </Grid>
          </Paper>
        )}
      </Grid>
    </div>
  );
};

export default SupportSubCategories;
